define("@dwind/checkout/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.router.on('routeDidChange', function () {
        if (!Ember.get(_this, 'fastboot.isFastBoot')) {
          window.scrollTo(0, 0);
        }
      });
    },
    beforeModel: function beforeModel() {
      return this.intl.setLocale(['it-it']);
    }
  });

  _exports.default = _default;
});