define("@dwind/checkout/models/checkout-step-shipping-config", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attr, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    shippingOptions: (0, _attributes.fragmentArray)('checkout-step-shipping-config-shippingoption'),
    otpRequired: (0, _attr.default)('boolean'),
    selectedStream: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    deliveryList: (0, _attr.default)(''),
    lastStep: (0, _attr.default)('boolean')
  });

  _exports.default = _default;
});