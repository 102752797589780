define("@dwind/checkout/controllers/checkout/steps", ["exports", "@dwind/widi-flow/mixins/widi-flow-controller"], function (_exports, _widiFlowController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_widiFlowController.default, {
    checkoutController: Ember.inject.controller('checkout'),
    config: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    widiGtm: Ember.inject.service(),
    intl: Ember.inject.service(),
    errors: Ember.observer('stepDataError.errors', function () {
      var errors = Ember.getWithDefault(this, 'stepDataError.errors', []);

      if (!Ember.isEmpty(errors)) {
        var error = errors.firstObject;

        if (error.status !== '422') {
          Ember.set(this, 'badRequestError', error);
          var intl = this.intl;
          var titleErrorTranslate = "errors.bad_request.".concat(error.code, ".title");
          Ember.set(this, 'titleError', intl.exists(titleErrorTranslate) ? intl.t(titleErrorTranslate) : error.title ? error.title : intl.t('errors.generic.title'));
          var detailErrorTranslate = "errors.bad_request.".concat(error.code, ".detail");
          Ember.set(this, 'detailError', intl.exists(detailErrorTranslate) ? intl.t(detailErrorTranslate) : error.detail ? error.detail : intl.t('errors.generic.detail'));
          var buttonErrorTranslate = "errors.bad_request.".concat(error.code, ".buttonTitle");
          Ember.set(this, 'buttonError', intl.exists(buttonErrorTranslate) ? intl.t(buttonErrorTranslate) : null);
        }
      }
    }),
    redirectErrors: Ember.observer('stepParams.errorCode', function () {
      var errorCode = Ember.get(this, 'stepParams.errorCode');

      if (errorCode) {
        var error = {};
        Ember.set(this, 'badRequestError', error);
        var intl = this.intl;
        var titleErrorTranslate = "errors.redirectErrors.".concat(errorCode, ".title");
        Ember.set(this, 'titleError', intl.exists(titleErrorTranslate) ? intl.t(titleErrorTranslate) : error.title ? error.title : intl.t('errors.generic.title'));
        var detailErrorTranslate = "errors.redirectErrors.".concat(errorCode, ".detail");
        Ember.set(this, 'detailError', intl.exists(detailErrorTranslate) ? intl.t(detailErrorTranslate) : error.detail ? error.detail : intl.t('errors.generic.detail'));
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var isFastBoot = Ember.get(this, 'fastboot.isFastBoot');

      if (!isFastBoot) {
        window.addEventListener('scroll', this._handleScroll.bind(this));
        document.body.addEventListener('click', this._handleScroll.bind(this), true);

        try {
          document.getElementsByTagName('body')[0].classList.remove('body--hidden');
        } catch (ex) {// Nothing
        }

        var cookiesAccepted = window.localStorage.getItem('cookiesAccepted') == 'true';
        Ember.set(this, 'cookiesAccepted', cookiesAccepted);
      } else {
        Ember.set(this, 'cookiesAccepted', true);
      }
    },
    _handleScroll: function _handleScroll() {
      this.acceptCookies();
      window.removeEventListener('scroll', this._handleScroll.bind(this));
      window.removeEventListener('click', this._handleScroll.bind(this));
    },
    acceptCookies: function acceptCookies() {
      if (!Ember.get(this, 'fastboot.isFastBoot') && !this.cookiesAccepted) {
        Ember.set(this, 'cookiesAccepted', true);
        window.localStorage.setItem('cookiesAccepted', 'true');
        var brand = 'windtre';

        try {
          brand = this.widiFlow.flow.toJSON().meta.brand.toLowerCase();
        } catch (ex) {// nothing at the moment.
        }

        var isGTMEnabled = this.widiGtm.enableGTM(brand);
        Ember.set(this, 'isGTMEnabled', isGTMEnabled);
      }
    },
    acceptGTM: function acceptGTM() {
      Ember.set(this, 'isGTMEnabled', true);
    },
    actions: {
      setReference: function setReference(context) {
        Ember.set(this, 'component', context);
      },
      dialogActionReference: function dialogActionReference() {
        var component = Ember.get(this, 'component');
        component.dialogAction();
      },
      setShippingPrice: function setShippingPrice(option) {
        var _this = this;

        Ember.run.scheduleOnce('afterRender', this, function () {
          Ember.set(_this, 'checkoutController.shippingPrice', option);
        });
      },
      setStreamPickup: function setStreamPickup(option) {
        var _this2 = this;

        Ember.run.scheduleOnce('afterRender', this, function () {
          Ember.set(_this2, 'checkoutController.pickstoreStreamSelected', option);
        });
      },
      closeModal: function closeModal(modalCode) {
        if (modalCode) {
          Ember.set(this.component, modalCode, false);
        } else {
          Ember.set(this, 'badRequestError', false);
        }
      },
      generateRandomNumber: function generateRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
      }
    }
  });

  _exports.default = _default;
});