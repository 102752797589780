define("@dwind/checkout/components/checkout-step-simfork", ["exports", "@dwind/widi-flow/mixins/widi-flow-step-component"], function (_exports, _widiFlowStepComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_widiFlowStepComponent.default, {
    stepInternalId: 1,
    store: Ember.inject.service(),
    widiList: Ember.inject.service(),
    widiCart: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      submitSIMChoice: function submitSIMChoice() {
        this.stepActions.submitStep();
      },
      onSimFlowChange: function onSimFlowChange(value) {
        if (value !== Ember.get(this, 'stepData.selectedSimFlow')) {
          Ember.set(this, 'stepData.selectedSimFlow', value);
          this.stepActions.submitStep(true).then(this._reloadCart.bind(this));
        }
      }
    },
    _reloadCart: function _reloadCart() {
      this.widiCart.reload();
    }
  });

  _exports.default = _default;
});