define("@dwind/checkout/models/checkout-step-personal-config", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attr, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    campaigns: (0, _attributes.fragmentArray)('campaigninfo'),
    personalDocument: (0, _attributes.fragment)('checkout-step-personal-config-document'),
    selectedStream: (0, _attr.default)('string')
  });

  _exports.default = _default;
});