define("@dwind/checkout/templates/components/checkout-cart-options-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VkKlyXtC",
    "block": "{\"symbols\":[\"radio\",\"option\",\"@category\",\"@changeCategoryOption\"],\"statements\":[[5,\"widi-radio\",[],[[\"@classNames\",\"@value\",\"@disabled\",\"@onChange\"],[\"checkout-cart-item-options-category flex layout-row\",[22,3,[\"selectedOptionId\"]],[22,3,[\"locked\"]],[27,\"action\",[[22,0,[]],[22,4,[]]],null]]],{\"statements\":[[0,\"\\n  \"],[6,[22,1,[\"title\"]],[],[[\"@classNames\"],[\"checkout-cart-item-options-category-title layout-row layout-align-space-between-start\"]],{\"statements\":[[0,\"\\n    \"],[1,[22,3,[\"labelName\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"each\",[[22,3,[\"optionItems\"]]],null,{\"statements\":[[0,\"    \"],[6,[22,1,[\"item\"]],[],[[\"@classNames\",\"@primary\",\"@value\"],[\"checkout-cart-item-options-category-option\",true,[22,2,[\"optionId\"]]]],{\"statements\":[[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"flex layout-row layout-align-space-between-center\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"checkout-cart-item-options-category-option-name\"],[9],[0,\"\\n          \"],[1,[22,2,[\"label\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"checkout-cart-item-options-category-option-price\"],[9],[0,\"\\n          \"],[5,\"widi-cart-price\",[],[[\"@amount\",\"@currency\"],[[22,2,[\"displayedPrice\"]],[22,2,[\"currency\"]]]]],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/checkout/templates/components/checkout-cart-options-category.hbs"
    }
  });

  _exports.default = _default;
});