define("@dwind/checkout/services/threedsdata", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    get3DSdata: function get3DSdata() {
      var threeDSdata = [{
        key: 'browserJavaEnabled',
        value: navigator.javaEnabled()
      }, {
        key: 'browserLanguage',
        value: navigator.language
      }, {
        key: 'browserColorDepth',
        value: screen.colorDepth
      }, {
        key: 'browserScreenHeight',
        value: screen.height
      }, {
        key: 'browserScreenWidth',
        value: screen.width
      }, {
        key: 'browserTZ',
        value: new Date().getTimezoneOffset()
      }, {
        key: 'browserUserAgent',
        value: navigator.userAgent
      }];
      return threeDSdata;
    }
  });

  _exports.default = _default;
});