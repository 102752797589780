define("@dwind/checkout/services/widi-gtm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    config: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    metrics: Ember.inject.service(),
    isGTMAlreadyEnabled: false,
    hasAppToken: false,
    setAppToken: function setAppToken(appToken) {
      this.hasAppToken = !!appToken;
    },
    enableGTM: function enableGTM() {
      var brand = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'windtre';
      var enabled = false;
      var isFastboot = Ember.get(this, 'fastboot.isFastBoot');

      if (!this.isGTMAlreadyEnabled && !isFastboot) {
        window.dataLayer = window.dataLayer || []; // window.dataLayer.push({ appToken: 'appToken' });
        //const cookiesAccepted = window.localStorage.getItem('cookiesAccepted') === 'true';

        if (this.config.get('GTM_CONFIG')[brand]) {
          /* try {
            // If an appToken has been set
            const token = this.hasAppToken || window.appToken || localStorage.getItem('appToken');
            if (token) {
              window.dataLayer = [{ appToken: 'appToken' }];
            }
          } catch (ex) {
            console.warn(ex.message);
          } */
          this.metrics.activateAdapters([{
            name: 'GoogleTagManager',
            environments: ['all'],
            config: {
              id: this.config.get('GTM_CONFIG')[brand] || 'IMPOSSIBLE' // TODO: Add generic GTM

            }
          }]);
          Ember.set(this, 'isGTMAlreadyEnabled', true);
          enabled = true;
        }
      }

      return enabled;
    }
  });

  _exports.default = _default;
});