define("@dwind/checkout/models/checkout-step-contract-config-tosdetail", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr"], function (_exports, _fragment, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    identifier: (0, _attr.default)('string'),
    isMandatory: (0, _attr.default)('boolean'),
    labelContent: (0, _attr.default)('string'),
    labelTitle: (0, _attr.default)('string')
  });

  _exports.default = _default;
});