define("@dwind/checkout/templates/components/checkout-cart-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kIIKjI26",
    "block": "{\"symbols\":[\"@totalPrice\"],\"statements\":[[7,\"div\"],[11,\"id\",\"checkout-cart-summary-total\"],[11,\"class\",\"layout-row flex layout-align-space-between\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"layout-row layout-align-start-center\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"id\",\"checkout-cart-summary-total-label\"],[9],[1,[27,\"t\",[\"cart.total\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"checkout-cart-summary-total-amount\"],[11,\"class\",\"checkout-cart-price layout-row layout-align-end-center\"],[9],[0,\"\\n    \"],[5,\"widi-cart-price\",[],[[\"@classNames\",\"@amount\",\"@currency\"],[\"checkout-cart-price-amount\",[22,1,[\"displayedPrice\"]],[22,1,[\"currency\"]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/checkout/templates/components/checkout-cart-summary.hbs"
    }
  });

  _exports.default = _default;
});