define("@dwind/checkout/translations/it-it", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "address": {
      "city": "Città",
      "street": "Indirizzo",
      "streetnumber": "Civico",
      "zipcode": "CAP"
    },
    "back": "indietro",
    "cancel": "Annulla",
    "cardPurchase": "Paga",
    "cart": {
      "additionalInfo": "L’Anticipo è definito in base al metodo di pagamento scelto. Il pagamento avverrà al momento della sottoscrizione del contratto direttamente in negozio.",
      "coupon": {
        "add": "Aggiungi",
        "label": "Inserici codice promozionale",
        "remove": "Rimuovi"
      },
      "free_shipping": "Gratuita",
      "options": {
        "add": "Modifica",
        "confirm": "Conferma",
        "hide": "-",
        "remove": "Rimuovi",
        "reset": "Reset",
        "show": "+",
        "title": "Opzione extra"
      },
      "price": {
        "fixed": "Una&nbsp;tantum"
      },
      "shipping": "Consegna",
      "title": "Riepilogo",
      "toggle": "Riepilogo",
      "total": "Totale",
      "unique-stream-title": {
        "STORE_PICKUP": "Riepilogo"
      },
      "warningMessage": "Al termine dell’acquisto, dopo pochi giorni dall’attivazione della SIM, riceverai una telefonata da un nostro operatore per confermare la tua offerta."
    },
    "close": "chiudi",
    "confirm": "conferma",
    "cookies": {
      "link": "Informazioni e gestione consenso cookie.",
      "policy": "Questo sito usa cookie, anche di terzi, per migliorare l’esperienza di navigazione e adeguare la pubblicità alle tue preferenze. Chiudendo questo banner o proseguendo nella navigazione acconsenti all’utilizzo di cookie."
    },
    "credit_card": {
      "cvv": "CVV",
      "date": "Data di scadenza",
      "month": "mm",
      "number": "Numero della carta",
      "year": "aa"
    },
    "errors": {
      "bad_request": {
        "already_paid": {
          "detail": "Questo ordine è già stato preso in carico.",
          "title": "Ordine completato."
        },
        "cart_inconsistent": {
          "detail": "Il carrello non è più valido.",
          "title": "Oops!"
        },
        "cart_validation": {
          "detail": "Il carrello non è più valido.",
          "title": "Oops!"
        },
        "check_credit_card_error": {
          "detail": "Per favore ricontrolla i tuoi dati e riprova.",
          "title": "Oops! Il pagamento non è andato a buon fine."
        },
        "critical_delivery": {
          "detail": "A causa delle disposizioni rivolte alla riduzione della diffusione del virus COVID-19 l’area selezionata non è al momento raggiungibile dall’operatore logistico.",
          "title": "Attenzione!"
        },
        "customer_not_found": {
          "detail": "Il cliente non è presente nei nostri archivi.",
          "title": "Oops!"
        },
        "customer_not_verified": {
          "detail": "Non è possibile sottoscrivere l'offerta online su questo numero di telefono.",
          "title": "Oops!"
        },
        "error_cvp": {
          "detail": "La tua richiesta non è stata presa in carico.",
          "title": "Oops!"
        },
        "expired_flow_time": {
          "buttonLabel": "Torna alla vetrina",
          "content": "La tua sessione è scaduta"
        },
        "flow_timeout": {
          "detail": "Questa sessione è scaduta. Per favore inserisci nuovamente i tuoi dati.",
          "title": "Oops!"
        },
        "incompatible_bundle_found": {
          "detail": "L’offerta selezionata non è compatibile con il tuo piano tariffario attuale. Ti aspettiamo nei <a href='https://www.windtre.it/trova-negozio/' target='_blank' rel='noopener'>Negozi WINDTRE</a> per proporti una soluzione adatta a te.",
          "title": "Oops! L’offerta è incompatibile con il tuo piano tariffario"
        },
        "invalid_age": {
          "detail": "Ti ricordiamo che per attivare una nuova SIM WINDTRE online, devi aver compiuto 14 anni.",
          "title": "Oops! Sembra che tu abbia meno di 14 anni!"
        },
        "invalid_flow": {
          "detail": "Non è possibile proseguire.",
          "title": "Oops!"
        },
        "invalid_mnp_not_eligible": {
          "detail": "La selezione non è disponibile per questo prodotto.",
          "title": "Oops!"
        },
        "invalid_mnp_tos": {
          "detail": "Per procedere devi accettare i termini di condizione del servizio.",
          "title": "Oops!"
        },
        "invalid_operator": {
          "detail": "Il seriale SIM inserito non corrisponde all’operatore selezionato.",
          "title": "Oops!"
        },
        "invalid_otp": {
          "detail": "Codice non valido",
          "title": "Oops!"
        },
        "invalid_phone_number": {
          "detail": "Numero di telefono non valido.",
          "title": "Oops!"
        },
        "invalid_taxcode": {
          "detail": "Il codice fiscale non è valido, per favore controlla i dati inseriti.",
          "title": "Oops!"
        },
        "invitation_code_not_valid": {
          "detail": "Il codice amico inserito non è valido.",
          "title": "Oops!"
        },
        "missing_iccid_in_order": {
          "detail": "Sei sicuro di aver già ricevuto la nuova SIM? Se ne sei in possesso, inserisci il seriale che trovi sul retro della SIM o sul cartoncino di supporto.",
          "title": "Oops!"
        },
        "not_a_wind_customer": {
          "detail": "Il numero inserito non è WINDTRE.",
          "title": "Oops!"
        },
        "not_accepted": {
          "detail": "Accetta tutti i termini del servizio per proseguire.",
          "title": "Oops! Per procedere devi accettare i termini di condizione del servizio"
        },
        "not_eligible": {
          "detail": "Non è possibile proseguire online. Per completare l’acquisto ti invitiamo a recarti al negozio più vicino.",
          "title": "Oops!"
        },
        "not_eligible_tryandbuy": {
          "detail": "Hai già usufruito del periodo di prova, non è possibile continuare con l’attivazione.",
          "title": "Oops!"
        },
        "notfuturedate": {
          "detail": "Data non valida",
          "title": "Oops!"
        },
        "order_not_found": {
          "detail": "Non è stata trovata nessuna utenza corrispondente ai dati inseriti. Prova a reinserirli e verifica che siano corretti.",
          "title": "Oops!"
        },
        "order_pending_found": {
          "detail": "Il cliente ha già una pratica aperta a suo nome.",
          "title": "Oops!"
        },
        "otp_max_limit_reached": {
          "buttonTitle": "Inserisci un nuovo numero di telefono",
          "detail": "Non puoi proseguire perchè hai già effettuato il numero massimo di acquisti possibili con questo numero di telefono.",
          "title": "Oops!"
        },
        "otp_not_sent": {
          "detail": "Non risulta alcun codice OTP inviato a questo numero",
          "title": "Oops!"
        },
        "payment_error": {
          "detail": "Per favore ricontrolla i tuoi dati e riprova.",
          "title": "Oops! Il pagamento non è andato a buon fine."
        },
        "payment_timeout": {
          "detail": "La richiesta non può essere completata. Riprovare più tardi.",
          "title": "Oops!"
        },
        "pos_error_mnp": {
          "detail": "Il numero inserito è associato a un'utenza WINDTRE. Passa a WINDTRE con il numero di un altro operatore o attiva un nuovo numero.",
          "title": "Oops!"
        },
        "secure_payment_failed": {
          "detail": "Il pagamento tramite la procedura 3D non è andato a buon fine.",
          "title": "Oops!"
        },
        "sims_limit_reached": {
          "detail": "Il cliente non può acquistare altre sim.",
          "title": "Oops!"
        },
        "unsupported_payment_type": {
          "detail": "Per favore ricontrolla i tuoi dati e riprova.",
          "title": "Oops! Il pagamento non è andato a buon fine."
        },
        "validation_cart_failed": {
          "detail": "La richiesta non può essere completata. Riprovare più tardi.",
          "title": "Oops!"
        },
        "video_identification_already_completed": {
          "detail": "Non è più possibile accedere alla video identificazione per attivare la tua SIM. Per avere maggiori informazioni controlla le email ricevute.",
          "title": "Non è possibile proseguire"
        },
        "video_identification_not_supported": {
          "detail": "La tua SIM non necessita di essere attivata tramite processo di video identificazione. Attendi 48 ore dalla consegna e la SIM si attiverà automaticamente."
        },
        "video_identification_still_unavailable": {
          "detail": "Riceverai una e-mail quando l'ordine sarà confermato e potrai iniziare il videoriconoscimento.",
          "title": "Il tuo ordine è in lavorazione"
        },
        "video_identification_unavailable": {
          "detail": "Non è più possibile accedere alla video identificazione per attivare la tua SIM. Per avere maggiori informazioni controlla le email ricevute.",
          "title": "Oops!"
        },
        "videoidentification_not_specified": {
          "detail": "L'opzione di videoidentificazione non è specificata.",
          "title": "Oops!"
        },
        "wrong_iccid_code": {
          "detail": "Il seriale della SIM inserito non è corretto. Prova a reinserirlo e verificane la correttezza.",
          "title": "Oops!"
        },
        "zipcode_not_found": {
          "detail": "Il CAP che hai inserito non è valido: potrebbe indicare una località non coperta dal nostro sistema di spedizioni oppure potrebbe essere stato digitato in modo non corretto. Riprova o recati presso uno dei nostri negozi per completare l’acquisto.",
          "title": "Oops!"
        }
      },
      "cart_items_not_found": {
        "detail": "Scopri subito la nuova offerta o cerca quella che preferisci su <b>windtre.it</b>.",
        "firstButtonLabel": "Scopri la nuova offerta",
        "secondButtonLabel": "Scopri altre offerte",
        "subtitle": "Non perdere le altre offerte per te",
        "title": "L'offerta non è più disponibile."
      },
      "check_ecommerce_failed": {
        "button": "trova negozio",
        "detail": "Al momento non possiamo procedere con la richiesta di acquisto, vieni a trovarci nei nostri negozi",
        "subtitle": "",
        "title": "Non possiamo procedere con la richiesta di acquisto"
      },
      "device_not_available": {
        "detail": "",
        "firstButtonLabel": "Scopri gli altri prodotti",
        "navigationText": "Oppure torna su <a class='blue-link' href='//www.windtre.it' target='_blank' rel='noopener'>windtre.it</a>.",
        "subtitle": "",
        "title": "Questa offerta al momento non è disponibile, ma lo sarà al più presto.<br>Torna a trovarci tra poco!"
      },
      "device_reservation_is_expired": {
        "detail": "",
        "firstButtonLabel": "Scopri gli altri prodotti",
        "navigationText": "Oppure torna su <a class='blue-link' href='//www.windtre.it' target='_blank' rel='noopener'>windtre.it</a>.",
        "subtitle": "",
        "title": "La sessione è scaduta e il prodotto non è più disponibile."
      },
      "error_payment_redirect": {
        "detail": "Se hai effettuato un pagamento, attendi qualche minuto e controlla di aver ricevuto la nostra email di riepilogo ordine.<br>In questo caso il pagamento è andato a buon fine.<br><br><br>Altrimenti, <a href='https://www.windtre.it/contattaci' target='_blank' rel='noopener'>contattaci</a>",
        "title": "Qualcosa è andato storto."
      },
      "expired_flow_time": {
        "buttonLabel": "Torna alla vetrina",
        "content": "La tua sessione è scaduta"
      },
      "field": {
        "expiredDate": "Data non valida",
        "invalidField": "Campo non valido",
        "invalidIdCardNumber": "il numero inserito non è corretto",
        "required": "Campo obbligatorio"
      },
      "generic": {
        "detail": "La richiesta non può essere completata. Riprovare più tardi.",
        "subtitle": "",
        "title": "Oops!"
      },
      "invalid_iccid": "Iccid non valido",
      "invalid_taxcode": "Codice fiscale non valido",
      "pos_error_max": {
        "detail": "Vai in negozio e ritira le SIM prenotate.",
        "subtitle": "",
        "title": "Hai già raggiunto il numero massimo di prenotazioni."
      },
      "pos_error_unavailable": {
        "detail": "Ripeti subito l'ordine per prenotare la tua nuova SIM WINDTRE.",
        "subtitle": "",
        "title": "Non è stato possibile effettuare la prenotazione a causa di un problema tecnico temporaneo."
      },
      "redirectErrors": {
        "authorization_denied": {
          "detail": "Autorizzazione negata. Verifica che i dati siano corretti o riprova con un'altra carta."
        },
        "card_number_not_valid": {
          "detail": "Carta di credito non valida. Verifica che i dati siano corretti o riprova con un'altra carta."
        },
        "credit_card_not_valid": {
          "detail": "Carta di credito non valida. Verifica che i dati siano corretti o riprova con un'altra carta."
        },
        "temporary_technical_error": {
          "detail": "Si è verificato un errore. Riprova tra qualche minuto."
        },
        "transaction_refused": {
          "detail": "La transazione è stata rifiutata. Verifica che i dati siano corretti o riprova con un'altra carta."
        }
      },
      "unauthorized_access": {
        "title": "Accesso negato."
      },
      "under_maintenance": {
        "detail": "",
        "subtitle": "",
        "title": "Stiamo aggiornando la nostra offerta. Ti invitiamo a riprovare più tardi."
      },
      "unknown_flow_type": {
        "detail": "Non è possibile iniziare un flusso del tipo richiesto.",
        "subtitle": "",
        "title": "Flusso sconosciuto"
      },
      "wrong_iccid_code": "Iccid non valido"
    },
    "footer": {
      "faq": "Consulta le <a href={link} target='_blank' rel='noopener'>FAQ</a>",
      "info": "WindTre S.p.A. - Partita IVA: 13378520152",
      "refund": "<a href={link} target='_blank' rel='noopener'>Diritto di recesso</a>",
      "terms": "<a href={link} target='_blank' rel='noopener'>Condizioni generali di acquisto online</a>"
    },
    "identification": {
      "helpers": {
        "document": {
          "text": "Lo stesso utilizzato in fase d'acquisto",
          "title": "Documento d'identità"
        },
        "iccid": {
          "text": "Sul retro della SIM o sul cartoncino di supporto dove vengono riportati anche il codice PIN e il codice PUK.",
          "title": "Dove puoi trovare il numero seriale?"
        },
        "sim": {
          "text": "Ultime cinque cifre del numero seriale",
          "title": "La tua nuova SIM"
        },
        "taxcode": {
          "title": "Codice fiscale"
        },
        "tel": {
          "text1": "Riceverai un SMS con il codice per la firma elettronica sul contatto telefonico indicato in fase d'acquisto",
          "title1": "Il tuo telefono"
        }
      },
      "iccid": "Seriale SIM (ultime 5 cifre)",
      "label": "VERIFICA LA TUA UTENZA",
      "subtitle": "Tieni a portata di mano",
      "taxcode": "Inserisci Codice Fiscale",
      "title": "Attiva subito la tua SIM!",
      "welcome": "Benvenuto"
    },
    "login": {
      "cta": {
        "cta": "Accedi",
        "text": " con le credenziali dell’Area Clienti e recupera automaticamente i tuoi dati."
      },
      "error": {
        "text1": "Username e password non valide.",
        "text2": "Verifica che siano corrette e riprova"
      },
      "logged": "Non vuoi utilizzare i tuoi dati?",
      "login_btn": "ENTRA",
      "login_recovery": "Username o Password dimenticata?",
      "logout": "Esci",
      "password": "Password",
      "remember_me": "Ricordami",
      "signup_btn": "REGISTRATI",
      "username": "Username"
    },
    "name": "Nome",
    "paypalPurchase": "<span class='icon'></span>Paga con Paypal",
    "progress": "stato di completamento",
    "purchase": "acquista",
    "recipient": "Destinatario (Nome Cognome)",
    "simBonusType": {
      "extraGiga": "+1 GIGA IN REGALO",
      "onlineDiscount": "PROMO SIM SCONTATA",
      "onlineFree": "PROMO SIM GRATUITA",
      "viDiscount": "SIM SCONTATA",
      "viFree": "SIM GRATUITA"
    },
    "steps": {
      "buyButtonLabel": "acquista",
      "complete": {
        "appButton": "torna alla home",
        "booking_code": "codice di prenotazione",
        "instructions": {
          "CASH_ON_DELIVERY": {
            "1": {
              "text": "Consegna al corriere una fotocopia fronte e retro del documento che hai usato per la registrazione.",
              "title": "Prepara i documenti"
            },
            "2": {
              "text": "La SIM può essere consegnata solo all'intestatario del contratto.",
              "title": "Devi essere presente di persona"
            },
            "3": {
              "text": "Paga comodamente in contanti alla consegna.",
              "title": "Effettua il pagamento"
            },
            "tiedOffer": {
              "1": {
                "text": "Consegna al corriere una fotocopia fronte e retro del documento che hai usato per la registrazione.",
                "title": "Prepara i documenti"
              },
              "2": {
                "text": "La SIM può essere consegnata solo all'intestatario del contratto.",
                "title": "Devi essere presente di persona"
              },
              "3": {
                "text": "Paga comodamente in contanti alla consegna.",
                "title": "Effettua il pagamento"
              }
            },
            "title": "Prossimi passi",
            "videoidentification": {
              "1": {
                "text": "Puoi farla ritirare da chi vuoi.",
                "title": "Consegna della SIM"
              },
              "2": {
                "text": "Per attivare la tua SIM",
                "title": "Vai su www.windtre.it/attiva"
              },
              "3": {
                "text": "Con la video identificazione attivi la SIM in meno di 5 minuti.",
                "title": "Attiva la tua nuova SIM"
              },
              "4": {
                "text": "Attendi l’SMS sulla nuova SIM per iniziare a usare la tua offerta.",
                "title": "Naviga e chiama"
              }
            }
          },
          "FDO": {
            "mail_reminder": "Nella mail di conferma troverai le istruzioni per accedere al VIDEORICONOSCIMENTO e attivare la tua SIM.",
            "title": "Prossimi passi"
          },
          "FULL_ONLINE": {
            "1": {
              "text": "Consegna al corriere una fotocopia fronte e retro del documento che hai usato per la registrazione.",
              "title": "Prepara i documenti"
            },
            "2": {
              "text": "La SIM può essere consegnata solo all'intestatario del contratto.",
              "title": "Devi essere presente di persona"
            },
            "3": {
              "text": "Entro poche ore dalla consegna la tua nuova SIM sarà attiva.",
              "title": "Attivazione della SIM"
            },
            "onlyDevice": {
              "1": {
                "text": "Consegna al corriere una fotocopia fronte e retro del documento che hai usato per la registrazione.",
                "title": "Prepara i documenti"
              },
              "2": {
                "text": "Il prodotto può essere consegnato solo all'intestatario dell'acquisto.",
                "title": "Devi essere presente di persona"
              },
              "3": {
                "text": "Inizia ad esplorare le funzionalità del tuo acquisto.",
                "title": "Usa il tuo nuovo prodotto"
              }
            },
            "tiedOffer": {
              "1": {
                "text": "Consegna al corriere una fotocopia fronte e retro del documento che hai usato per la registrazione.",
                "title": "Prepara i documenti"
              },
              "2": {
                "text": "La SIM può essere consegnata solo all'intestatario del contratto.",
                "title": "Devi essere presente di persona"
              },
              "3": {
                "text": "Entro poche ore dalla consegna la tua nuova SIM sarà attiva.",
                "title": "Attivazione della SIM"
              }
            },
            "title": "Prossimi passi",
            "videoidentification": {
              "1": {
                "text": "Puoi farla ritirare da chi vuoi.",
                "title": "Attendi la consegna della SIM"
              },
              "2": {
                "text": "Per attivare la tua SIM",
                "title": "Vai su www.windtre.it/attiva"
              },
              "3": {
                "text": "Con la video identificazione attivi la SIM in meno di 5 minuti.",
                "title": "Attiva la tua nuova SIM"
              },
              "4": {
                "text": "Attendi l’SMS sulla nuova SIM per iniziare a usare la tua offerta.",
                "title": "Naviga e chiama"
              },
              "tiedOffer": {
                "1": {
                  "text": "Puoi farla ritirare da chi vuoi.",
                  "title": "Attendi la consegna della SIM"
                },
                "2": {
                  "text": "Per attivare la tua SIM",
                  "title": "Vai su www.windtre.it/attiva"
                },
                "3": {
                  "text": "Con la video identificazione attivi la SIM in meno di 5 minuti.",
                  "title": "Attiva la tua nuova SIM"
                },
                "4": {
                  "text": "Attendi l’SMS sulla nuova SIM per iniziare ad usarla.<br>Tieni la SIM in utilizzo per lo <b>step 5</b>.",
                  "title": "Naviga e chiama"
                },
                "5": {
                  "text": "Riceverai una telefonata da un operatore per confermare la tua <b>offerta mensile</b>.",
                  "title": "Chiamata dell'operatore"
                }
              }
            }
          },
          "STORE_PICKUP": {
            "1": {
              "icon": "ic_code",
              "text": "Tieni a portata di mano il codice di prenotazione che trovi qui sopra.",
              "title": "Codice di prenotazione"
            },
            "2": {
              "icon": "ic_id_card",
              "text": "Porta con te un documento d’identità.",
              "title": "DOCUMENTO D’IDENTITÁ"
            },
            "3": {
              "icon": "ic_sim",
              "text": "Se passi a WindTre, ricorda di portare con te anche la SIM dell’operatore di provenienza.",
              "title": " La tua SIM"
            },
            "text": "Ricorda che per ritirare la tua SIM, potrai recarti in un qualsiasi Negozio WindTre <b>entro il {expirationPickUpDate}</b>",
            "tiedOffer": {
              "1": {
                "icon": "ic_code",
                "text": "Tieni a portata di mano il codice di prenotazione che trovi qui sopra.",
                "title": "Codice di prenotazione"
              },
              "2": {
                "icon": "ic_id_card",
                "text": "Porta con te un documento d’identità.",
                "title": "DOCUMENTO D’IDENTITÁ"
              },
              "3": {
                "icon": "ic_sim",
                "text": "Se passi a WindTre, ricorda di portare con te anche la SIM dell’operatore di provenienza.",
                "title": " La tua SIM"
              }
            },
            "title": "Ricordati di portare con te in negozio"
          }
        },
        "summary": {
          "address": "L'ordine sarà spedito a",
          "delivery": "Data di consegna prevista",
          "shipping": {
            "text": "La modalità di spedizione è",
            "title": "Modalità di Spedizione"
          },
          "title": "Ordine n. {order}"
        },
        "thankyou": {
          "CASH_ON_DELIVERY": {
            "text": "La tua richiesta è stata ricevuta con successo.",
            "title": "Grazie per il tuo ordine"
          },
          "FULL_ONLINE": {
            "text": "La tua richiesta è stata ricevuta con successo.",
            "title": "Grazie per il tuo ordine"
          },
          "STORE_PICKUP": {
            "EVOLVED": {
              "subtitle": "Prima di recarti in negozio, dovrai attendere la chiamata di un operatore per la conferma della prenotazione.",
              "text": "Il negozio ti ricontatterà al più presto al numero da te fornito.",
              "title": "Grazie!"
            },
            "mail_reminder": "Ti ricordiamo che riceverai una mail di conferma con il codice.",
            "selected_dealer": {
              "title": "{dealer_type} Windtre"
            },
            "sms_send": "Invia il codice via SMS",
            "store_info_section_title": "negozio selezionato",
            "text": "Abbiamo ricevuto la tua richiesta, riceverai presto una mail di conferma con il tuo codice di prenotazione",
            "title": "Grazie per la tua prenotazione!"
          },
          "dealer": {
            "title": "Il processo di Firma Cliente/Rivenditore è completato"
          },
          "fdo": {
            "code": "Ordine n. <strong class='accent'>{order}</strong>",
            "costs": "Riceverai una mail con le istruzioni per avviare il <b>video riconoscimento</b> e <b>attivare la tua nuova SIM</b>.",
            "text": "Ti ringraziamo per il tuo ordine.",
            "tied": {
              "costs": "Riceverai una mail di conferma entro e non oltre le 48h con le istruzioni per avviare il <b>video riconoscimento</b> e <b>attivare la tua nuova SIM</b>.",
              "orderCvp": "Ti ricordiamo che non è stato addebitato alcun importo sulla tua carta di credito",
              "text": "Abbiamo <b>preso in carico la tua richiesta</b>."
            },
            "title": "<strong class='accent'>GRAZIE!</strong>"
          },
          "orderCvp": {
            "text": "Al momento non abbiamo prelevato nessun importo dalla tua carta di credito. Addebiteremo l'importo dovuto solo alla conferma dell'ordine che ti verrà comunicata via mail."
          },
          "tied": {
            "code": "Ordine n. <strong class='accent'>{order}</strong>",
            "costs": "Ti ricordiamo che non è stato addebitato alcun importo sulla tua carta di credito",
            "text": "Riceverai una mail di conferma al più presto e comunque non oltre le 48h.",
            "title": "Stiamo elaborando la tua richiesta, <strong class='accent'>GRAZIE!</strong>"
          },
          "user": {
            "alt": "mail",
            "subtitle": "Il contratto firmato sarà inviato via mail",
            "title": "Il processo di Firma Cliente è completato"
          }
        },
        "tutorial": "scopri come attivare"
      },
      "completeOrderLabel": "concludi ordine",
      "continueButtonLabel": "continua",
      "contract": {
        "conditions_title": "Termini e condizioni",
        "download_contract_button_label": "scarica il pdf",
        "download_contract_field": "Confermo di aver visionato la sintesi contrattuale e confermo di accettare l'offerta ivi descritta",
        "iccid": {
          "title": "ICCID",
          "whatis": {
            "text": "L'ICCID (numero seriale) è un numero di 19 cifre che inizia con 8939 e identifica univocamente la tua SIM. L'ICCID serve per effettuare la portabilità del numero da un operatore all'altro.",
            "title": "Cos'e'?"
          },
          "whereto": {
            "ios": {
              "text": "Impostazioni > Generali > Info > ICCID",
              "title": "Sul tuo smartphone iOS"
            },
            "sim": {
              "text": "Sul retro della tua sim e sul cartoncino di supporto dove vengono riportati anche il codice PIN e il codice PUK.",
              "title": "Sulla tua SIM"
            },
            "title": "Dove lo puoi trovare"
          }
        },
        "portability": {
          "MNP": {
            "text": "Passi a WINDTRE mantenendo il tuo attuale numero,<br>l'eventuale credito residuo sarà trasferito automaticamente sulla tua nuova SIM.",
            "title": "Voglio mantenere il mio numero"
          },
          "NEW_NUMBER": {
            "text": "Attiva un nuovo numero WINDTRE",
            "title": "Voglio un nuovo numero"
          },
          "accept_contract": "Dichiaro di accettare le <a href={link} target='_blank' rel='noopener'>Condizioni generali del servizio</a> e ne richiedo la <strong>fornitura immedita</strong>",
          "conditions": "Continuando con la richiesta della SIM WINDTRE dichiari di conoscere e accettare le <a href={terms} target='_blank' rel='noopener'>Condizioni generali del Servizio mobile WINDTRE</a> e di essere consapevole che in caso di portabilità per completare l'attivazione è necessario accettare le condizioni del servizio <a href={mnp} target='_blank' rel='noopener'>Passa a WINDTRE</a>.",
          "iccid": "Numero SIM (ICCID)",
          "mobileNumber": "Numero di cellulare",
          "operator": "Operatore di provenienza",
          "plan": "Tipo di tariffa attiva",
          "refund": "Selezionando la casella WINDTRE attiverà al più presto il servizio. Inoltre accetti che in caso di recesso ai sensi dell'art. 52 del codice del consumo ti sarà addebitato il traffico effettuato. Per saperne di più sulle modalità del diritto di recesso <a target='_blank' rel='noopener' href={link}>clicca qui</a>.",
          "subtitles": {
            "MNP": "Passa a WINDTRE da un altro operatore mantenendo il tuo numero",
            "NEW_NUMBER": "La SIM verrà attivata con nuovo numero WINDTRE"
          },
          "title": "Portabilità",
          "titles": {
            "NEW_NUMBER": "Fornitura e Consensi"
          }
        },
        "title": "La tua linea",
        "tos": {
          "accept": "<b>Acconsento a tutti i trattamenti</b> indicati nell'informativa privacy (facoltativi)",
          "hide": "Nascondi tutti i consensi",
          "oneshot": "Scegli la tua privacy",
          "privacy": "Per poter proseguire con l'acquisto, scarica il PDF e verifica le condizioni contrattuali e accetta le condizioni generali del servizio.<br>WINDTRE tratterà i tuoi dati personali esclusivamente per le finalità connesse alla gestione della tua richiesta, per maggiori informazioni <a href={link} target='_blank' rel='noopener'>clicca qui.</a>",
          "show": "Mostra tutti i consensi"
        }
      },
      "customer-identification": {
        "askForOtp": "RICHIEDI CODICE",
        "info": "Digita il tuo numero di telefono per ricevere un SMS con il codice da inserire qui sotto per verificare se puoi attivare l’offerta.",
        "newOtp": "Richiedi un nuovo codice",
        "notReceived": "<b>Non hai ricevuto il codice?</b> Attendi 30 secondi e potrari richiederlo",
        "otpSent": "Il codice è stato inviato tramite SMS al tuo numero di telefono.",
        "tel": "Numero di cellulare",
        "title": "Verifica il tuo numero di telefono",
        "verify": "VERIFICA"
      },
      "fdoiccid": {
        "iccidBlock": "Inserisci il codice ICCID",
        "iccidInput": "******",
        "iccidPrefix": "****",
        "sectionTitle": "DOVE TROVARE IL CODICE ICCID:",
        "title": "Inserisci il numero ICCID che trovi sul retro della card della tua nuova SIM WINDTRE da attivare:"
      },
      "flowTimeMinuteLabel": "Minuto",
      "flowTimeMinutesLabel": "Minuti",
      "flowTimeSecondLabel": "Secondo",
      "flowTimeSecondsLabel": "Secondi",
      "flowTimerEndMessage": " Terminato",
      "flowTimerLimitLabel": "Tempo residuo: ",
      "fork": {
        "residence": {
          "info": "Un ultimo passaggio prima di completare il tuo ordine.",
          "title": "Indirizzo di residenza"
        },
        "stream": "Seleziona la modalità di pagamento",
        "stream_options": {
          "CASH_ON_DELIVERY": {
            "conditions": "Attivazione {activationPrice}",
            "description": "Riceverai la SIM tramite corriere all'indirizzo da te comunicato e potrai pagare in contanti alla consegna.",
            "label": "In contrassegno"
          },
          "FULL_ONLINE": {
            "conditions": "Attivazione {activationPrice}",
            "description": "I successivi rinnovi dell'offerta saranno addebitati sul credito residuo. Riceverai la SIM tramite corriere a un indirizzo da te scelto.",
            "label": "Carta di credito o PayPal",
            "tiedOffer": {
              "description": "Completa il tuo acquisto online",
              "label": "Carta di credito"
            }
          },
          "STORE_PICKUP": {
            "conditions": "Attivazione {activationPrice}",
            "description": "Riceverai un codice di prenotazione, valido 7 giorni, per ritirare e pagare la tua SIM in qualsiasi negozio WINDTRE.",
            "label": "In negozio"
          }
        },
        "unique-stream": {
          "STORE_PICKUP": "Prenotazione"
        }
      },
      "payment": {
        "addPayment": {
          "text1": "Aggiungi un nuovo metodo di pagamento",
          "text2": "Inserisci un nuovo metodo di pagamento e completa l'acquisto"
        },
        "credit_card": {
          "cvvTooltip": {
            "amexText": "Solo se hai un’American Express il CVV è posto sulla parte frontale della carta ed è composto da 4 cifre.",
            "subtitle": "Dove lo puoi trovare?",
            "text": "Inserisci le ultime tre cifre presenti sul retro della tua carta.",
            "title": "CVV"
          },
          "text": "Inserisci i dati della tua carta di credito",
          "title": "Carta di credito"
        },
        "endsWith": "termina con",
        "expires": "Scadenza",
        "new": "modalità di pagamento",
        "onEarlyTermination": {
          "description": "La scelta effettuata è modificabile in qualunque momento, contattando il Servizio Clienti WINDTRE.",
          "firstChoice": "Confermo la volontà di continuare a godere del piano di rateizzazione nel caso di recesso.",
          "secondChoice": "Esprimo la volontà di corrispondere gli importi residui in un’unica soluzione in caso di recesso.",
          "title": "Scelta della modalità di pagamento in caso di recesso anticipato a tutela del consumatore:"
        },
        "payWithPaypal": "Paga con il tuo account <b>Paypal</b>",
        "paypal": {
          "text": "Clicca sul pulsante PayPal per procedere con il pagamento.",
          "title": "PayPal"
        },
        "recipient": "Intestatario",
        "saved": {
          "description": "Seleziona uno dei tuoi metodi di pagamento e conferma l'acquisto."
        },
        "subtitle": "Seleziona uno dei metodi di pagamento e prosegui con l'acquisto.",
        "tied": {
          "activation": {
            "tiedInstallmentActivation": "Costo di attivazione di {oneshotActivation}€ in 24 rate mensili di {installmentLeftActivation}€. <strong>Sconto</strong> di {installmentLeftActivation}€ sul costo mensile dell'offerta per la durata della rateizzazione, con offerta attiva 24 mesi.",
            "tiedInstallmentActivationInnerText": "In caso di recesso anticipato:",
            "tiedInstallmentSingleActivation": "Addebito rate residue in unica soluzione (scelta consigliata)",
            "tiedInstallmentWithDrawal": "Mantenimento della rateizzazione fino alla scadenza",
            "tiedOneShotActivation": "Costo di attivazione di {oneshotActivation}€ interamente addebitato al primo ciclo di fatturazione",
            "title": "COSTO ATTIVAZIONE OFFERTA"
          },
          "info": "La carta sarà utilizzata per il pagamento dei costi mensili e per il costo dell'attivazione dell'offerta"
        },
        "tryAndBuy": "Applicabile al termine del periodo di prova"
      },
      "paymentcc": {
        "cvvTooltip": {
          "label": "ok",
          "text": "Il CVV è il codice di 3 o 4 cifre presente sul retro delle carte Visa e Mastercard, o sul fronte delle carte American Express",
          "title": "Dove si trova il codice CVV?"
        },
        "subtitle": "INSERISCI I DETTAGLI DELLA TUA CARTA",
        "title": "PAGAMENTO CON CARTA DI CREDITO",
        "total": "Importo:"
      },
      "personal": {
        "contacts": {
          "confirmEmail": "Conferma e-mail",
          "confirmPec": "Conferma PEC",
          "email": "E-mail",
          "pec": "PEC (facoltativo)",
          "tel": "Numero di cellulare",
          "title": "Contatti"
        },
        "document": {
          "date": "Data di rilascio",
          "number": "Numero",
          "title": "Documento",
          "type": "Tipo di documento"
        },
        "gdpr": "Sono consapevole che chiunque rilasci dichiarazioni mendaci è punito ai sensi del codice penale e delle leggi speciali in materia e dichiaro quanto sopra ai sensi e per gli effetti degli artt. 46 e 47 del D.P.R. n. 445/2000; dichiaro, altresì, di essere consapevole che nessuna responsabilità potrà essere attribuita a WINDTRE qualora detta dichiarazione risultasse essere falsa, e, quindi, di manlevare WINDTRE da ogni conseguente azione di responsabilità e/o pretesa dovesse essere effettuata da parte di terzi.",
        "identity": {
          "taxcode": "Codice fiscale",
          "title": "Identità"
        },
        "mobileNrTooltipText": "Il numero di telefono che inserisci qui sarà utilizzato esclusivamente per eventuali comunicazioni legate a questo ordine.",
        "omocity": "Città o Nazione",
        "privacy": {
          "ppe": "Richiedo di essere ricontattato (in ottemperanza a quanto previsto dalle disposizioni della Legge 5/2018) da parte del Dealer da me selezionato, a seguito dell’interesse manifestato per un prodotto di Wind Tre, per concordare un appuntamento presso quel punto vendita di interesse.<br><br>Premendo sul tasto <b>CONTINUA</b>, autorizzo pertanto Wind Tre S.p.a. a trattare i miei dati personali per le finalità sopra indicate e come meglio descritte nell' <a href={link} target='_blank' rel='noopener'>Informativa Privacy</a> ai sensi e per gli effetti dell’art 13 del GDPR.",
          "standard": "Per proseguire è necessario che tu fornisca alcuni dati personali. WINDTRE tratterà tali dati esclusivamente per le finalità connesse alla gestione della tua richiesta.<br><br><a href={link} target='_blank' rel='noopener'>Visualizza l'Informativa sul trattamento dei dati personali</a>."
        },
        "requiredFields": "Tutti i campi sono obbligatori",
        "residence": {
          "info": "Un ultimo passaggio prima di completare il tuo ordine.",
          "title": "Indirizzo di residenza"
        },
        "stream": "Come vuoi completare l'acquisto?",
        "tiedOffer": {
          "attentionMessage": "Dopo l'attivazione della SIM, entro 24 ore lavorative, verrai contattato da un nostro operatore per finalizzare l'attivazione dell’offerta EASY PAY scelta. Nel caso non dovessi rispondere entro 30 giorni, potrai continuare ad utilizzare la SIM con <a target='_blank' rel='noopener' href={link}>il piano Basic a consumo</a>."
        },
        "title": "I tuoi dati"
      },
      "reserveButtonLabel": "prenota",
      "shipping": {
        "SMART_DELIVERY": "Spedizione e identificazione tramite corriere",
        "VIDEO_IDENTIFICATION": "Spedizione e video identificazione",
        "address": "Dati di spedizione",
        "delivery": "Consegna",
        "deliveryType": {
          "reminder": {
            "SMART_DELIVERY": {
              "label": "Spedizione e identificazione tramite corriere",
              "mandatory": {
                "text": "L'identificazione dell'intestatario del contratto avverrà tramite il corriere che ritirerà una copia del tuo documento.",
                "title": "Ricorda che devi essere presente di persona alla consegna."
              },
              "onlyDevice": {
                "text": "Il device può essere consegnato solo all'intestatario dell'acquisto.",
                "title": "Ricordati di essere presente di persona alla consegna"
              },
              "text": "L'identificazione dell'intestatario del contratto avverrà tramite il corriere che ritirerà una copia del tuo documento.",
              "title": "Con questa modalità devi essere presente di persona alla consegna."
            },
            "VIDEO_IDENTIFICATION": {
              "label": "Spedizione e video identificazione",
              "mandatory": {
                "text": "Le comunicazioni saranno inviate a",
                "title": "Non è necessario che tu sia presente alla consegna della SIM."
              },
              "text": "Attivi la tua nuova SIM online tramite video identificazione. Le comunicazioni saranno inviate a",
              "title": "Non è necessario che tu sia presente alla consegna della SIM."
            }
          }
        },
        "discount": {
          "discountTooltip": {
            "extraGiga": "Il giga in regalo è stato aggiunto",
            "viDiscount": "Lo sconto SIM è stato applicato",
            "viFree": "Lo sconto SIM è stato applicato"
          },
          "freeShipping": "<strong>CONSEGNA GRATUITA</strong>: disponibile"
        },
        "identification": {
          "option_1": {
            "title": "Spedizione e identificazione tramite corriere"
          },
          "option_2": {
            "title": "Spedizione e video identificazione"
          },
          "question": "Modalità di spedizione",
          "reminder": {
            "text": "Attivi la tua nuova SIM online tramite video identificazione. Le comunicazioni saranno inviate a",
            "title": "Non è necessario che tu sia presente alla consegna della SIM."
          }
        },
        "options": {
          "invalid": "Non sono disponibili modalità di spedizione per il CAP fornito.",
          "missing": "Inserisci il CAP per scoprire la data di consegna.",
          "text": "La consegna verrà effettuata",
          "title": "Data di consegna"
        },
        "otp": {
          "confirm": "CONFERMA",
          "invalidOtp": "Codice non valido",
          "label": "Inserisci il codice di 6 cifre appena inviato al numero di cellulare che hai inserito nelle schermate precedenti.",
          "newOtp": "Invia un nuovo codice",
          "title": "CODICE DI CONFERMA"
        },
        "residence": "Indirizzo di residenza",
        "sameaddress": {
          "notify": "Potrai modificare l'indirizzo di residenza accedendo alla tua Area Clienti",
          "title": "Utilizza questo indirizzo anche come indirizzo di residenza"
        }
      },
      "simfork": {
        "option": {
          "fdo": {
            "description": "Prosegui per attivare la SIM che hai con te",
            "label": "Si, da attivare"
          },
          "standard": {
            "description": "Inserisci i dati necessari e ricevi la tua SIM da attivare",
            "label": "No, non ancora"
          }
        },
        "title": "Sei già in possesso di una SIM WINDTRE?"
      },
      "storechoice": {
        "choiceInfo": "Apri la mappa e trova il negozio più vicino a te",
        "choiceTitle": "Ritira in negozio",
        "ctaLabelChange": "Cambia negozio WINDTRE",
        "ctaLabelChoice": "Scegli negozio WINDTRE",
        "iconAlt": "pin mappa",
        "iframeTitle": "Seleziona il Negozio WINDTRE che preferisci",
        "title": "Riceverai <b>un codice di prenotazione per ritirare</b> e pagare i prodotti selezionati"
      },
      "tiedInfo": {
        "isTryAndBuy": "Il primo pagamento  dell'offerta e del costo di attivazione, saranno addebitati sulla tua carta di credito indicativamente dopo <b>40 giorni</b> dal termine del periodo di prova.",
        "ppe": "Il primo pagamento  dell'offerta e del costo di attivazione, saranno addebitati sulla tua carta di credito indicativamente dopo <b>40 giorni</b> dall’attivazione della SIM.",
        "standard": "Il primo pagamento  dell'offerta e del costo di attivazione, saranno addebitati sulla tua carta di credito indicativamente dopo <b>40 giorni</b> dall’attivazione della SIM."
      }
    },
    "surname": "Cognome",
    "tooltip": {
      "generalConditionsTooltip": "La SIM che riceverai non è ancora attiva. Nel caso tu voglia che l’attivazione del servizio avvenga prima della scadenza dei 14 giorni previsti per il diritto di recesso ai sensi dell’art. 52 del Codice del Consumo, procedi con la video identificazione appena ricevuta la SIM. In caso volessi esercitare il diritto di recesso entro i 14 giorni, accetti che ti venga addebitato il traffico effettuato. Invece, nel caso tu non voglia la fornitura immediata del servizio potrai decidere di effettuare la video identificazione decorso il termine di 14 giorni dalla consegna della SIM. Per saperne di più sulle modalità del diritto di recesso <a href={link} target='_blank' rel='noopener'>clicca qui</a>.",
      "generalConditionsTooltipFDO": "La SIM che hai con te non è ancora attiva. Nel caso tu voglia che l’attivazione del servizio avvenga prima della scadenza dei 14 giorni previsti per il diritto di recesso ai sensi dell’art. 52 del Codice del Consumo, procedi con la video identificazione appena ricevuta la SIM. In caso volessi esercitare il diritto di recesso entro i 14 giorni, accetti che ti venga addebitato il traffico effettuato. Invece, nel caso tu non voglia la fornitura immediata del servizio potrai decidere di effettuare la video identificazione decorso il termine di 14 giorni dalla consegna della SIM. Per saperne di più sulle modalità del diritto di recesso <a href={link} target='_blank' rel='noopener'>clicca qui</a>.",
      "mobileNumberTooltip": "Il numero di telefono che inserisci qui sarà utilizzato esclusivamente per eventuali comunicazioni legate a questo ordine.",
      "pecTooltip": "Se preferisci ricevere la fattura elettronica su un indirizzo di Posta Elettronica Certificata (PEC) al posto del contatto e-mail standard, inseriscila qui.",
      "sameaddressTooltip": "L'indirizzo di residenza è necessario per completare i dati di anagrafica all'interno della proposta di contratto.",
      "sameaddressTooltipOnlyDevice": "L'indirizzo di residenza è necessario per completare i dati di anagrafica."
    },
    "widi-cart": {
      "price": {
        "free": "gratuita"
      }
    },
    "widi-uikit": {
      "errors": {
        "validation": {
          "invalid": "Campo non valido",
          "required": "Campo obbligatorio"
        }
      },
      "submit": {
        "continue": "Continua"
      }
    }
  };
  _exports.default = _default;
});