define("@dwind/checkout/templates/thankyou/dealer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gk0rWYhl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-row layout-align-space-around-center checkout-step-complete full-height\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"checkout-step-complete-thankyou\"],[11,\"class\",\"layout-column layout-align-space-around-center tied\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"id\",\"checkout-step-complete-thankyou-title \"],[9],[0,\"\\n      \"],[7,\"h2\"],[9],[0,\"\\n\"],[0,\"        \"],[1,[27,\"t\",[\"steps.complete.thankyou.dealer.title\"],null],true],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/checkout/templates/thankyou/dealer.hbs"
    }
  });

  _exports.default = _default;
});