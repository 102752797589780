define("@dwind/checkout/components/checkout-step-shipping", ["exports", "@dwind/widi-flow/mixins/widi-flow-step-component"], function (_exports, _widiFlowStepComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_widiFlowStepComponent.default, {
    stepInternalId: 5,
    widiList: Ember.inject.service(),
    widiUser: Ember.inject.service(),
    widiCart: Ember.inject.service(),
    store: Ember.inject.service(),
    shippingOptions: Ember.computed.alias('stepConfig.shippingOptions'),
    errors: Ember.observer('stepDataError', function () {
      var errors = Ember.getWithDefault(this, 'stepDataError.errors', []);

      if (!Ember.isEmpty(errors)) {
        var errorCode = errors.firstObject.code;
        Ember.set(this, 'errorCode', errorCode);

        switch (errorCode) {
          case 'invalid_otp':
            Ember.set(this, 'invalidOtp', true);
            Ember.set(this, 'stopTimer', false);
            break;

          case 'otp_max_limit_reached':
            Ember.set(this, 'otpModalVisible', false);
            Ember.set(this, 'otp_max_limit_reached', true);
            break;
        }
      }
    }),
    isVideoIdentification: Ember.computed('selectedDeliveryType', {
      get: function get() {
        return this.selectedDeliveryType == this.CONSTANTS.OPTIONS.VIDEO_IDENTIFICATION;
      }
    }).readOnly(),
    sameaddressTooltipText: Ember.computed('selectedFlow', {
      get: function get() {
        return this.selectedFlow == this.CONSTANTS.OPTIONS.DEVICE_ONE_SHOT ? 'sameaddressTooltipOnlyDevice' : 'sameaddressTooltip';
      }
    }),
    userIsLoggedIn: Ember.computed('widiUser.authorization', {
      get: function get() {
        return Ember.isPresent(this.widiUser.authorization);
      }
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.get(this, 'setReference')(this);
      Ember.set(this, 'stepData.cmdSendOtp', false);
      Ember.set(this, 'simBonusType', this.store.peekRecord('widi-cart', this.widiCart.cartId).simBonusType);
      Ember.set(this, 'selectedDeliveryType', this.stepData.deliveryType);
      var deliveryList = this.stepConfig.deliveryList;
      Ember.set(this, 'onlyOneDeliveryType', deliveryList.length === 1);
      var shippingAddressCity = Ember.get(this, 'stepData.shippingAddress.city');

      if (!Ember.isPresent(shippingAddressCity)) {
        var shippingZipCode = Ember.get(this, 'stepData.shippingAddress.zipcode');

        if (!Ember.isEmpty(shippingZipCode)) {
          this.actions.searchAddressCityByZipCode.bind(this)('stepData.shippingAddress.city', 'shippingAddressCityText', 'shippingAddressCityisClean', shippingZipCode);
        }
      } else {
        this.stepActions.submitStep(true);
      }

      var shippingResidenceCity = Ember.get(this, 'stepData.shippingResidence.city');

      if (!Ember.isPresent(shippingResidenceCity)) {
        var residenceZipCode = Ember.get(this, 'stepData.shippingResidence.zipcode');

        if (!Ember.isEmpty(residenceZipCode)) {
          this.actions.searchResidenceCityByZipCode.bind(this)('stepData.shippingResidence.city', 'shippingResidenceCityText', 'shippingResidenceCityisClean', residenceZipCode);
        }
      }

      if (!Ember.isEmpty(this.simBonusType)) {
        this._reloadCart();
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var shippingId = Ember.get(this, 'stepData.shippingId');

      if (Ember.get(this, 'invalidOtp')) {
        Ember.set(this, 'stopTimer', false);
      }

      var options = Ember.get(this, 'shippingOptions');

      if (shippingId) {
        Ember.set(this, 'selectedShippingId', shippingId);
        var option = options.findBy('optionId', shippingId);
        this.stepActions.setShippingPrice(option);
      } else {
        var _option = options.findBy('isSelected', true);

        Ember.set(this, 'selectedShippingId', _option ? Ember.get(_option, 'optionId') : null);
      }
    },
    dialogAction: function dialogAction() {
      if (Ember.get(this, 'otp_max_limit_reached')) {
        Ember.set(this, 'stopTimer', false);
        this.newMobileNumberRequest();
      }
    },
    newMobileNumberRequest: function newMobileNumberRequest() {
      Ember.set(this, 'stepData.cmdModifyPhoneNumber', true);
      this.stepActions.submitStep(true).then(this.stepActions.closeModal());
    },
    _setAddressResidence: function _setAddressResidence(clear) {
      var _this = this;

      ['street', 'streetnumber', 'zipcode', 'city'].forEach(function (key) {
        var value = clear ? null : Ember.get(_this, "stepData.shippingAddress.".concat(key));
        Ember.set(_this, "stepData.shippingResidence.".concat(key), value);
      });
    },
    _showOtpModal: function _showOtpModal() {
      if (!Ember.get(this, 'stepDataError')) {
        Ember.set(this, 'otpModalVisible', true);

        this._blockOtpRequests();
      }
    },
    _blockOtpRequests: function _blockOtpRequests() {
      var _this2 = this;

      Ember.set(this, 'newOtpRequestAvailable', false);
      Ember.set(this, 'stopTimer', false);
      var runLater = Ember.run.later(function () {
        Ember.set(_this2, 'newOtpRequestAvailable', true);
        Ember.set(_this2, 'randomNr', _this2.stepActions.generateRandomNumber(1, 100000));
      }, 30000);
      Ember.set(this, 'runLater', runLater);
    },
    _reloadCart: function _reloadCart() {
      this.widiCart.reload();
    },
    actions: {
      toggleTooltip: function toggleTooltip(tooltip) {
        this.toggleProperty(tooltip);
        var tooltip_property = Ember.get(this, tooltip);
        Ember.set(this, 'tooltipVisible', tooltip_property);
        Ember.set(this, 'tooltipType', tooltip_property ? tooltip : null);
      },
      shippingDeliveryTypeChanged: function shippingDeliveryTypeChanged(value) {
        Ember.set(this, 'selectedDeliveryType', value);
        Ember.set(this, 'shippingDiscountTooltip', value == this.CONSTANTS.OPTIONS.VIDEO_IDENTIFICATION && this.simBonusType);
        Ember.set(this, 'stepData.deliveryType', this.selectedDeliveryType);
        this.stepActions.submitStep(true).then(this._reloadCart.bind(this));
      },
      newOtpRequest: function newOtpRequest() {
        Ember.set(this, 'stepData.cmdSendOtp', true);
        this.stepActions.submitStep(true).then(this._blockOtpRequests());
      },
      closeOtpModal: function closeOtpModal() {
        Ember.set(this, 'otpModalVisible', false);
        Ember.set(this, 'invalidOtp', false);
        Ember.set(this, 'stepData.userOtp', '');
        Ember.set(this, 'randomNr', this.stepActions.generateRandomNumber(1, 100000));
        Ember.run.cancel(Ember.get(this, 'runLater'));
      },
      setShippingOption: function setShippingOption(id) {
        Ember.set(this, 'selectedShippingId', id);
        var option = Ember.get(this, 'shippingOptions').findBy('optionId', id);
        this.stepActions.setShippingPrice(option);
      },
      searchCityById: function searchCityById(listId, elementId) {
        var widiList = Ember.get(this, 'widiList');
        return widiList.searchById(listId, elementId);
      },
      searchCityByTerm: function searchCityByTerm(listId, term) {
        var _this3 = this;

        var widiList = Ember.get(this, 'widiList');
        var zipResponse = Ember.get(this, 'citiesList');
        return widiList.searchByTerm(listId, term).then(function (res) {
          var filter;

          if (Ember.get(_this3, 'stepData.shippingAddress.zipcode') && zipResponse.length > 1) {
            filter = zipResponse.filter(function (city) {
              var label = city.label.toLowerCase();
              return label.indexOf(term) > -1;
            });
          } else {
            filter = res;
          }

          return filter;
        });
      },
      searchAddressCityByZipCode: function searchAddressCityByZipCode(dataTarget, textTarget, isCleanTarget, zipcode) {
        var _this4 = this;

        Ember.set(this, 'citiesList', []);

        if (zipcode !== undefined && zipcode.length === 5) {
          Ember.get(this, 'widiList').searchByFilter('shippingDetails', 'zipcode', zipcode).then(function (cities) {
            if (!_this4.isDestroyed) {
              if (cities.length === 1) {
                Ember.set(_this4, "".concat(dataTarget), Ember.get(cities, 'firstObject.id'));
                Ember.set(_this4, "".concat(textTarget), Ember.get(cities, 'firstObject.label'));
                Ember.set(_this4, "".concat(isCleanTarget), true);
              } else {
                Ember.set(_this4, "".concat(textTarget), null);
                Ember.set(_this4, "".concat(dataTarget), null);
              }

              Ember.set(_this4, 'hasFocus', false);
              Ember.set(_this4, 'stepData.cmdSendOtp', false);

              _this4.stepActions.submitStep(true);
            }
          });
        }
      },
      searchResidenceCityByZipCode: function searchResidenceCityByZipCode(dataTarget, textTarget, isCleanTarget, zipcode) {
        var _this5 = this;

        if (zipcode !== undefined && zipcode.length === 5) {
          Ember.get(this, 'widiList').searchByFilter('shippingDetails', 'zipcode', zipcode).then(function (cities) {
            if (!_this5.isDestroyed) {
              if (cities.length === 1) {
                Ember.set(_this5, "".concat(dataTarget), Ember.get(cities, 'firstObject.id'));
                Ember.set(_this5, "".concat(textTarget), Ember.get(cities, 'firstObject.label'));
                Ember.set(_this5, "".concat(isCleanTarget), true);
              }
            }
          });
        }
      },
      sameAddressChanged: function sameAddressChanged(value) {
        Ember.set(this, 'stepData.sameAddress', value);

        if (value !== true) {
          this._setAddressResidence(true);
        }
      },
      sendShippingInfo: function sendShippingInfo() {
        Ember.set(this, 'stepData.deliveryType', this.selectedDeliveryType);
        Ember.set(this, 'stepData.useragent', window.WURFL && window.WURFL.form_factor || 'Desktop');
        var shippingId = Ember.get(this, 'selectedShippingId');
        Ember.set(this, 'stepData.shippingId', shippingId);

        if (Ember.get(this, 'stepData.sameAddress')) {
          this._setAddressResidence();
        }

        var shippingOptions = Ember.get(this, 'shippingOptions');
        var shipping = shippingOptions.findBy('optionId', shippingId);
        var shippingType = shipping ? Ember.get(shipping, 'type') : null;

        if (shipping) {
          this.addMetric('shipping', (shippingType || '').toLowerCase());
        }

        if (!this.isFdo) {
          this.addMetric('isSameAddress', Ember.get(this, 'stepData.sameAddress'));
        }

        var otpRequired = Ember.get(this, 'stepConfig.otpRequired');
        Ember.set(this, 'stepData.cmdSendOtp', otpRequired); // GTM: Track specific option.

        this.actions.trackShippingOption.call(this);

        if (Ember.get(this, 'stepData.shippingAddress')) {
          if (Ember.isBlank(Ember.get(this, 'stepData.shippingAddress.streetnumber'))) {
            Ember.set(this, 'stepData.shippingAddress.streetnumber', '');
          }
        }

        if (Ember.get(this, 'stepData.shippingResidence')) {
          if (Ember.isBlank(Ember.get(this, 'stepData.shippingResidence.streetnumber'))) {
            Ember.set(this, 'stepData.shippingResidence.streetnumber', '');
          }
        }

        this.stepActions.submitStep(otpRequired).then(!Ember.isBlank(Ember.get(this, 'stepData.shippingAddress.streetnumber')) || !Ember.isBlank(Ember.get(this, 'stepData.shippingResidence.streetnumber')) ? otpRequired ? this._showOtpModal.bind(this) : {} : {});
      },
      changeOtpCode: function changeOtpCode(value) {
        Ember.set(this, 'invalidOtp', false);
        Ember.set(this, 'stepData.userOtp', value);
      },
      trackShippingOption: function trackShippingOption() {
        try {
          if (this.widiFlow.flow.meta.tagInfo.isVideoidentification != null || (this.stepConfig.deliveryList || []).indexOf('VIDEO_IDENTIFICATION') > -1) {
            this.trackOption(this.isVideoIdentification ? 'videoriconoscimento' : 'postino intelligente');
          } else {
            this.trackOption('no sim');
          }
        } catch (ex) {// nothing
        }
      },
      sendOtp: function sendOtp() {
        //const shippingId = get(this, 'selectedShippingId');
        //const shippingOptions = get(this, 'shippingOptions');
        //const shipping = shippingOptions.findBy('optionId', shippingId);
        //let shippingType = shipping ? get(shipping, 'type') : null
        // GTM: Track specific option.
        this.actions.trackShippingOption.call(this);
        Ember.set(this, 'stopTimer', true);
        this.stepActions.submitStep();
      }
    }
  });

  _exports.default = _default;
});