define("@dwind/checkout/components/checkout-step-paymentcc", ["exports", "@dwind/widi-flow/mixins/widi-flow-step-component"], function (_exports, _widiFlowStepComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_widiFlowStepComponent.default, {
    stepInternalId: 6,
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    validations: Ember.computed({
      get: function get() {
        var _this = this;

        return {
          expireMonth: [{
            message: this.intl.t('errors.field.expiredDate'),
            validate: function validate(value) {
              var dateObject = new Date();
              var currentMonth = dateObject.getUTCMonth() + 1;
              var year = Ember.get(_this, 'stepData.paymentMethodData.expireYear');
              var currentYear = dateObject.getUTCFullYear().toString().substring(2, 4);

              if (value) {
                if (year && year == currentYear) {
                  return value.length == 2 && value >= 1 && value >= currentMonth && value <= 12;
                } else if (year < currentYear) {
                  return false;
                }

                return value.length == 2 && value >= 1 && value <= 12;
              }
            }
          }],
          expireYear: [{
            message: this.intl.t('errors.field.expiredDate'),
            validate: function validate(value) {
              var dateObject = new Date();
              var currentYear = dateObject.getUTCFullYear().toString().substring(2, 4);
              return value && value.length == 2 && value >= currentYear && value <= Number(currentYear) + 20;
            }
          }]
        };
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.resetFields();
      this.stepActions.stopLoading();
      Ember.set(this, 'paymentType', 'credit_card');
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var errors = Ember.get(this, 'stepDataError.errors');

      if (!Ember.isEmpty(errors) && errors.code != 'flow_timeout') {
        this.actions.openTimerAlert(false);
      }

      var threeDsRedirectUrl = Ember.get(this, 'stepConfig.threeDsRedirectUrl');

      if (threeDsRedirectUrl) {
        window.location.href = threeDsRedirectUrl;
      }
    },
    getPaymentLabel: function getPaymentLabel(type) {
      switch (type) {
        case this.CONSTANTS.OPTIONS.NEW_PAYPAL:
        case this.CONSTANTS.OPTIONS.PAYPAL:
          return 'paypal';

        case this.CONSTANTS.OPTIONS.SAVED_CREDIT_CARD:
        case this.CONSTANTS.OPTIONS.NEW_CREDIT_CARD:
          return 'carta di credito';

        default:
          return type;
      }
    },
    resetFields: function resetFields() {
      var paymentMethodData = this.store.createFragment('paymentMethodData');
      Ember.set(this, 'stepData.paymentMethodData', paymentMethodData);
      Ember.set(this, 'stepDataError', null);
      Ember.set(this, 'expireDate', null);
    },
    actions: {
      openTimerAlert: function openTimerAlert(status) {
        Ember.set(this, 'stopTimer', status);
      },
      toggleTooltip: function toggleTooltip(tooltip) {
        this.toggleProperty(tooltip);
        var tooltip_property = Ember.get(this, tooltip);
        Ember.set(this, 'tooltipVisible', tooltip_property);
        Ember.set(this, 'tooltipType', tooltip_property ? tooltip : null);
      },
      startNewPayment: function startNewPayment() {
        var _this2 = this;

        Ember.setProperties(this.stepData, {
          newPaymentType: this.CONSTANTS.OPTIONS.NEW_CREDIT_CARD,
          userAgent: window.WURFL && window.WURFL.form_factor || 'Desktop',
          newPaymentMethod: true
        });
        this.addMetric('paidWith', 'CREDIT_CARD'); // GTM: Track specific option.

        this.trackOption(this.getPaymentLabel(this.CONSTANTS.OPTIONS.SAVED_CREDIT_CARD));
        this.stepActions.submitStep().then(function () {
          _this2.actions.openTimerAlert(true);
        });
      }
    }
  });

  _exports.default = _default;
});