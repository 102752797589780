define("@dwind/checkout/models/textinput", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attr, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    label: (0, _attr.default)('string'),
    placeholder: (0, _attr.default)('string'),
    tooltip: (0, _attributes.fragment)('tooltip')
  });

  _exports.default = _default;
});