define("@dwind/checkout/templates/components/checkout-cart-selected-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ds5oOzgw",
    "block": "{\"symbols\":[\"option\",\"@category\",\"@cartLocked\"],\"statements\":[[4,\"each\",[[22,2,[\"optionItems\"]]],null,{\"statements\":[[4,\"liquid-if\",[[27,\"eq\",[[22,1,[\"optionId\"]],[22,2,[\"selectedOptionId\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"checkout-cart-selected-option\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"layout-row layout-align-space-between-end\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"checkout-cart-item-title\"],[9],[0,\"\\n          \"],[1,[22,1,[\"label\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"checkout-cart-price\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"checkout-cart-price-amount\"],[9],[0,\"\\n            \"],[5,\"widi-cart-price\",[],[[\"@amount\",\"@currency\"],[[22,1,[\"displayedPrice\"]],[22,1,[\"currency\"]]]]],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"layout-row layout-align-space-between-end\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"checkout-cart-option-remove\"],[11,\"role\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[27,\"not\",[[22,3,[]]],null],[27,\"not\",[[22,2,[\"locked\"]]],null]],null]],null,{\"statements\":[[0,\"            \"],[1,[27,\"t\",[\"cart.options.remove\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[3,\"action\",[[22,0,[]],[23,[\"changeCategoryOption\"]],null]],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"checkout-cart-price-text\"],[9],[0,\"\\n          \"],[1,[22,1,[\"frequency\"]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"checkout-cart-selected-option-divider\"],[9],[0,\"\\n\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/checkout/templates/components/checkout-cart-selected-options.hbs"
    }
  });

  _exports.default = _default;
});