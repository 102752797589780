define("@dwind/checkout/routes/checkout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    metrics: Ember.inject.service(),
    widiCart: Ember.inject.service(),
    model: function model(params) {
      Ember.set(this, 'widiCart.cartId', Ember.get(params, 'cart_id'));
      return Ember.get(this.widiCart, 'cart');
    }
    /*,
    setupController(controller, model) {
      this._super(...arguments);
      //const products = get(model, 'frontCartItems').mapBy('productId');
      //set(this, 'metrics.context.products', products)
    }*/

  });

  _exports.default = _default;
});