define("@dwind/checkout/models/checkout-step-complete-config", ["exports", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes", "ember-data/attr"], function (_exports, _fragment, _attributes, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    userName: (0, _attr.default)('string'),
    orderNumber: (0, _attr.default)('string'),
    shippingAddress: (0, _attributes.fragment)('checkout-step-complete-config-address'),
    shippingType: (0, _attr.default)('string'),
    deliveryDayOfWeek: (0, _attr.default)('string'),
    deliveryDay: (0, _attr.default)('string'),
    streamType: (0, _attr.default)('string'),
    videoidentification: (0, _attr.default)('boolean'),
    improvedNetwork: (0, _attr.default)('boolean'),
    bonusBanner: (0, _attr.default)(''),
    selectedDealer: (0, _attributes.fragment)('storedata'),
    expirationPickUpDate: (0, _attr.default)('string')
  });

  _exports.default = _default;
});