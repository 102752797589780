define("@dwind/checkout/components/checkout-cart-item-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['checkout-cart-item-detail']
  });

  _exports.default = _default;
});