define("@dwind/checkout/routes/landing", ["exports", "jwt-decode"], function (_exports, _jwtDecode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    router: Ember.inject.service(),
    widiCart: Ember.inject.service(),
    widiFlow: Ember.inject.service(),
    widiGtm: Ember.inject.service(),
    config: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.router.on('routeWillChange', function (_ref) {
        var _ref$to = _ref.to,
            params = _ref$to.params,
            queryParams = _ref$to.queryParams;
        Ember.set(_this, 'params', params);
        Ember.set(_this, 'queryParams', queryParams);
      });
    },
    model: function model() {
      var _this$params = this.params,
          flow_name = _this$params.flow_name,
          partner = _this$params.partner;

      var _this$_getRequestData = this._getRequestData(this.queryParams),
          cookies = _this$_getRequestData.cookies,
          data = _this$_getRequestData.data;

      var SECOND_SIM_PARTNER = 'SelfWeb1'; // Open for all PPE

      var shouldShowNewFlow = !(data && data.flow && data.flow === 'old'); // data && data.flow !== 'old' && (data.flow === 'ob' || (!!data.token /*&& partner !== SECOND_SIM_PARTNER*/))

      if (shouldShowNewFlow) {
        if (Ember.get(this, 'fastboot.isFastBoot')) {
          throw new Error({
            code: 'new_flow_error',
            status: '500',
            title: '',
            detail: ''
          });
        }
        /*
          <input type="hidden" name="campaignId" value={{@campaignId}}>
          <input type="hidden" name="operator" value={{@operator}}>
          <input type="hidden" name="msisdn" value={{@phone}}>
        */


        var baseApiBody = {
          Products: {
            mobileProducts: {
              secondSimToken: null,
              accessToken: null,
              productIds: null,
              metaData: null,
              ppeData: null,
              partnerId: partner,
              cookies: cookies,
              winBackData: null,
              inviteCode: null,
              trackCampaignData: null,
              mnpOtpEnabled: false
            },
            landLineProducts: null
          }
        }; // eslint-disable-next-line

        console.warn('CURRENT DATA'); // eslint-disable-next-line

        console.warn(data);

        if (!Object.keys(data).length) {
          throw {
            code: 'new_flow_error',
            status: '500',
            title: '',
            detail: ''
          };
        }

        try {
          if (data.backUrl) {
            if (data.backUrl.indexOf('http') > -1) {
              baseApiBody.Products.backUrl = data.backUrl;
            } else {
              baseApiBody.Products.backUrl = atob(data.backUrl);
            }
          } else {
            baseApiBody.Products.backUrl = document.referrer;
          }
        } catch (ex) {
          console.warn('Could not find the document referrer');
        } // const start = new Date(2022, 9, 26, 15, 0, 1);
        // const end = new Date(2022, 9, 26, 18, 1, 0);
        // const today = new Date();


        var isMnpForced = true; // (today >= start && today <= end) && (partner || '').toLowerCase() === 'ecommerce';

        if (data.mnpOtpEnabled || isMnpForced) {
          baseApiBody.Products.mobileProducts.mnpOtpEnabled = true;
        }

        if (data.productIds) {
          baseApiBody.Products.mobileProducts.productIds = data.productIds;
        }

        if (data.accessToken) {
          baseApiBody.Products.accessToken = data.accessToken;
          baseApiBody.Products.mobileProducts.accessToken = data.accessToken;
        }

        if (data.inviteCode) {
          baseApiBody.Products.mobileProducts.inviteCode = data.inviteCode;
        }

        var winBackData = {};

        if (data.campaignId) {
          winBackData.campaignId = data.campaignId;
        }

        if (data.operator || data.operatorId) {
          winBackData.operatorId = data.operator || data.operatorId;
        }

        if (data.msisdn) {
          winBackData.msisdn = data.msisdn;
        }

        if (!Object.keys(winBackData).length) {
          winBackData = null;
        }

        baseApiBody.Products.mobileProducts.winBackData = winBackData;
        var trackCampaignData = {};

        if (data.utm_source) {
          trackCampaignData.source = data.utm_source;
        }

        if (data.utm_medium) {
          trackCampaignData.medium = data.utm_medium;
        }

        if (data.utm_campaign) {
          trackCampaignData.campaign = data.utm_campaign;
        }

        if (data.utm_term) {
          trackCampaignData.term = data.utm_term;
        }

        if (data.utm_content) {
          trackCampaignData.content = data.utm_content;
        }

        if (data.custom_variable1) {
          trackCampaignData.variable1 = data.custom_variable1;
        }

        if (data.custom_variable2) {
          trackCampaignData.variable2 = data.custom_variable2;
        }

        if (!Object.keys(trackCampaignData).length) {
          trackCampaignData = null;
        }

        baseApiBody.Products.mobileProducts.trackCampaignData = trackCampaignData; // Manage secondSim or ppe

        if (data.token) {
          if (partner === SECOND_SIM_PARTNER) {
            // SecondSim
            baseApiBody.Products.mobileProducts.secondSimToken = data.token;
          } else {
            var decodedToken = null;

            try {
              decodedToken = (0, _jwtDecode.default)(data.token);
              decodedToken = JSON.parse(decodedToken.data);
            } catch (ex) {
              decodedToken = null;
            }

            baseApiBody.Products.mobileProducts.ppeData = decodedToken;
            baseApiBody.Products.mobileProducts.winBackData = null;
          }
        }

        var apiUrl = this.config.get('newFlowApiHost');
        fetch(apiUrl, {
          method: 'POST',
          body: JSON.stringify(baseApiBody)
        }).then(function (res) {
          return res.json();
        }).then(function (res) {
          if (window.location.href.includes('debug')) {
            setTimeout(function () {
              window.location.replace(res.redirect);
            }, 60000);
          } else {
            window.location.replace(res.redirect);
          }
        });
        throw {
          code: 'new_flow_error',
          status: '500',
          title: '',
          detail: ''
        };
      }

      try {
        document.getElementsByTagName('body')[0].classList.remove('body--hidden');
      } catch (ex) {// Nothing
      }

      var widiFlow = this.widiFlow;
      return widiFlow.start(null, flow_name, partner, {
        cookies: cookies,
        data: data
      });
    },
    afterModel: function afterModel(model) {
      if (!model) {
        return;
      }

      try {
        document.getElementsByTagName('body')[0].classList.remove('body--hidden');
      } catch (ex) {// Nothing
      }

      var _EmberGetProperties = Ember.getProperties(model, 'id', 'type'),
          id = _EmberGetProperties.id,
          type = _EmberGetProperties.type;

      var nextStepName = Ember.get(model, 'steps.lastObject.name');
      var nextStepId = Ember.get(model, 'steps.lastObject.id');
      var cartId = Ember.get(model, 'meta.cartId');
      Ember.set(this, 'widiCart.cartId', cartId);
      this.replaceWith('checkout.steps', cartId, type, id, nextStepName, nextStepId);
    },
    _getRequestData: function _getRequestData(queryParams) {
      var data = queryParams,
          values = [];

      if (Ember.get(this, 'fastboot.isFastBoot')) {
        var request = Ember.get(this, 'fastboot.request');

        var _EmberGetProperties2 = Ember.getProperties(request, 'body', 'cookies', 'method', 'queryParams'),
            body = _EmberGetProperties2.body,
            cookies = _EmberGetProperties2.cookies,
            method = _EmberGetProperties2.method,
            _queryParams = _EmberGetProperties2.queryParams;

        data = method === 'GET' ? _queryParams : body;
        values = Object.keys(cookies || {}).map(function (key) {
          return {
            key: key,
            value: Ember.get(cookies, key)
          };
        });
      }

      return {
        cookies: {
          values: values
        },
        data: data
      };
    }
  });

  _exports.default = _default;
});