define("@dwind/checkout/routes/identification/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    identification: Ember.inject.service(),
    config: Ember.inject.service(),
    model: function model(params, _ref) {
      var token = _ref.queryParams.token;
      return this.identification.validate({
        token: token
      }).catch(this._handleError.bind(this));
    },
    _handleError: function _handleError() {
      return {
        link: null,
        iccid: null,
        orderid: null,
        orderId: null,
        taxcode: null
      };
    }
  });

  _exports.default = _default;
});