define("@dwind/checkout/models/activationtied", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr"], function (_exports, _fragment, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    installmentActivation: (0, _attr.default)('string'),
    oneshotActivation: (0, _attr.default)('string'),
    leftActivation: (0, _attr.default)('string'),
    installmentLeftActivation: (0, _attr.default)('string')
  });

  _exports.default = _default;
});