define("@dwind/checkout/models/checkout-step-contract-data", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attr, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    mnp: (0, _attr.default)('boolean'),
    provisionAccepted: (0, _attr.default)('boolean'),
    mnpDetails: (0, _attributes.fragment)('checkout-step-contract-data-mnpdetails'),
    tosAccepted: (0, _attr.default)('boolean'),
    mainStatus: (0, _attributes.fragment)('checkout-step-contract-data-tosstatus'),
    tosStatus: (0, _attributes.fragmentArray)('checkout-step-contract-data-tosstatus'),
    contractConditionsAccepted: (0, _attr.default)('boolean')
  });

  _exports.default = _default;
});