define("@dwind/checkout/components/checkout-step-storechoice", ["exports", "@dwind/widi-flow/mixins/widi-flow-step-component"], function (_exports, _widiFlowStepComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_widiFlowStepComponent.default, {
    stepInternalId: 7,
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    listenIframeMessage: function listenIframeMessage() {
      var _this = this;

      window.addEventListener('message', function (e) {
        var sender = _this.widiFlow.config.get('environment') === 'development' ? 'https://pre.windtre.it' : 'https://www.windtre.it';

        if (e.origin !== sender) {
          return;
        }

        var dealer = e.data.dealer;
        var selectedDealer = {
          dealerId: dealer.dealerid,
          address: dealer.indirizzo.toLowerCase(),
          city: dealer.comune.toLowerCase(),
          province: dealer.siglaprov,
          phone: dealer.telefono,
          zipCode: dealer.cap,
          dealerType: dealer.tipologia
        };

        if (selectedDealer.city && selectedDealer.address && selectedDealer.dealerId) {
          Ember.set(_this, 'stepData.selectedDealer', selectedDealer);
        } else {
          Ember.set(_this, 'stepData.selectedDealer', null);
        }

        Ember.set(_this, 'storeChoiceModal', false);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.listenIframeMessage();
    },
    actions: {
      sendStoreData: function sendStoreData() {
        window.removeEventListener('message');
        this.stepActions.submitStep();
      },
      openChoiceModal: function openChoiceModal() {
        var dealer = Ember.get(this, 'stepData.selectedDealer');
        var customEvent = {
          'eventAction': 'click',
          'eventLabel': ''
        };
        Ember.set(this, 'storeChoiceModal', true);
        dealer.dealerId ? customEvent.eventLabel = 'Cambia negozio WINDTRE' : customEvent.eventLabel = 'Scegli negozio WINDTRE';
        this.trackCustomEvent(customEvent);
      }
    }
  });

  _exports.default = _default;
});