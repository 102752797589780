define("@dwind/checkout/controllers/application", ["exports", "@dwind/checkout/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    CONSTANTS: _constants.default,
    checkout: Ember.inject.controller('checkout'),
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});