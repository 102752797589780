define("@dwind/checkout/templates/components/checkout-payment-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rpAHQ787",
    "block": "{\"symbols\":[\"configItem\",\"@secureLink\"],\"statements\":[[7,\"form\"],[11,\"id\",\"checkout-post-form\"],[12,\"action\",[22,2,[]]],[11,\"method\",\"POST\"],[9],[0,\"\\n  \"],[7,\"input\"],[11,\"name\",\"URLBACK\"],[12,\"value\",[21,\"urlBack\"]],[11,\"type\",\"hidden\"],[9],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"configItems\"]]],null,{\"statements\":[[0,\"    \"],[7,\"input\"],[12,\"name\",[22,1,[\"name\"]]],[12,\"value\",[22,1,[\"value\"]]],[11,\"type\",\"hidden\"],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/checkout/templates/components/checkout-payment-redirect.hbs"
    }
  });

  _exports.default = _default;
});