define("@dwind/checkout/models/checkout-step-contract-data-mnpdetails", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr"], function (_exports, _fragment, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    mobileNumber: (0, _attr.default)('string'),
    operator: (0, _attr.default)('string'),
    simSerialNumber: (0, _attr.default)('string'),
    tariffPlan: (0, _attr.default)('string')
  });

  _exports.default = _default;
});