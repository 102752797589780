define("@dwind/checkout/templates/components/checkout-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AKpCeoff",
    "block": "{\"symbols\":[],\"statements\":[[4,\"click-outside\",null,[[\"action\"],[[27,\"action\",[[22,0,[]],[23,[\"toggleTooltip\"]]],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"checkout-tooltip \",[21,\"tooltipType\"]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"checkout-tooltip-text\"],[9],[0,\"\\n\"],[0,\"      \"],[1,[27,\"t\",[[27,\"concat\",[\"tooltip.\",[23,[\"tooltipType\"]]],null]],null],true],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/checkout/templates/components/checkout-tooltip.hbs"
    }
  });

  _exports.default = _default;
});