define("@dwind/checkout/templates/components/checkout-cart-campaign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R4rRS1OC",
    "block": "{\"symbols\":[\"@campaign\"],\"statements\":[[7,\"img\"],[11,\"id\",\"checkout-cart-campaign-banner\"],[12,\"src\",[22,1,[\"bannerUrl\"]]],[12,\"alt\",[22,1,[\"code\"]]],[12,\"title\",[22,1,[\"code\"]]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/checkout/templates/components/checkout-cart-campaign.hbs"
    }
  });

  _exports.default = _default;
});