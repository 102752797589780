define("@dwind/checkout/controllers/identification/user", ["exports", "@dwind/widi-uikit/mixins/widi-form-validated"], function (_exports, _widiFormValidated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    config: Ember.inject.service(),
    data: Ember.computed.alias('model'),
    fastboot: Ember.inject.service(),
    intl: Ember.inject.service(),
    identification: Ember.inject.service(),
    validations: Ember.computed({
      get: function get() {
        return {
          iccid: [{
            message: this.intl.t('errors.field.invalidField'),
            validate: function validate(value) {
              return /^\d{5}$/.test(value);
            }
          }],
          taxcode: [{
            message: 'Campo non valido',
            validate: _widiFormValidated.Taxcode
          }]
        };
      }
    }),
    actions: {
      identifyUser: function identifyUser(data) {
        Ember.set(this, 'isLoading', true);
        this.identification.validate(data).then(this._handleResponse.bind(this)).catch(this._handleError.bind(this));
      },
      hideError: function hideError() {
        Ember.set(this, 'error', null);
      }
    },
    _handleResponse: function _handleResponse(data) {
      var _this = this;

      var link = Ember.get(data, 'link');
      setTimeout(function () {
        if (!Ember.isEmpty(link)) {
          window.location.replace(link);
          Ember.set(_this, 'isLoading', false);
        }
      }, 3000);
    },
    _handleError: function _handleError(error) {
      var _this2 = this;

      var detail = Ember.get(error, 'payload');
      var field;

      if (!Ember.isEmpty(detail)) {
        var code = Ember.getWithDefault(detail, 'code', '');

        switch (code) {
          case 'invalid_taxcode':
            field = 'taxcode';
            break;

          case 'wrong_iccid_code':
          case 'order_not_found':
          case 'missing_iccid_in_order':
          case 'invalid_iccid':
          case 'video_identification_not_supported':
          case 'video_identification_already_completed':
          case 'video_identification_still_unavailable':
          case 'video_identification_unavailable':
            field = 'iccid';
            break;
        }
      }

      setTimeout(function () {
        if (!Ember.isEmpty(field)) {
          var _code = Ember.getWithDefault(detail, 'code', '');

          Ember.set(detail, 'detail', _this2.intl.t("errors.".concat(_code)));
          Ember.set(detail, 'source', {
            pointer: field
          });
          Ember.set(_this2, 'errors', {
            errors: [detail]
          });
          Ember.set(_this2, 'error', _this2.errors.errors.firstObject);
        } else {
          Ember.set(_this2, 'error', error);
        }

        Ember.set(_this2, 'isLoading', false);
      }, 3000);
    }
  });

  _exports.default = _default;
});