define("@dwind/checkout/models/paymentmethodinfo", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr"], function (_exports, _fragment, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    alias: (0, _attr.default)('string'),
    circuitId: (0, _attr.default)('string'),
    expire: (0, _attr.default)('string'),
    invalid: (0, _attr.default)('boolean'),
    labelCircuit: (0, _attr.default)('string'),
    maskedAccount: (0, _attr.default)('string'),
    typeaccount: (0, _attr.default)('string'),
    paymentId: (0, _attr.default)('string')
  });

  _exports.default = _default;
});