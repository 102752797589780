define("@dwind/checkout/components/checkout-paypal-payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    config: Ember.inject.service(),
    classNames: ['checkout-paypal-payment'],
    tag: 'form',
    env: Ember.computed.readOnly('config.paypalEnv'),
    didInsertElement: function didInsertElement() {
      var _this = this;

      var _EmberGetProperties = Ember.getProperties(this, 'elementId', 'env'),
          elementId = _EmberGetProperties.elementId,
          env = _EmberGetProperties.env;

      window.paypalCheckoutReady = function () {
        Ember.run.scheduleOnce('afterRender', _this, function () {
          paypal.Button.render({
            env: env,
            style: {
              size: 'responsive',
              color: 'blue',
              shape: 'pill',
              height: 50,
              label: 'pay',
              tagline: 'false'
            },
            payment: function payment(resolve, reject) {
              var token = _this.paypalToken;

              if (token) {
                resolve(token);

                _this.paypalActions.openTimerAlert(true);
              } else {
                reject();

                _this.paypalActions.error();
              }
            },
            onAuthorize: function onAuthorize(data, actions) {
              var token = Ember.get(data, 'paymentToken');

              _this.paypalActions.end(token);

              return actions.close;
            },
            onCancel: function onCancel(data, actions) {
              _this.paypalActions.openTimerAlert(false);

              _this.paypalActions.cancel();

              return actions.close;
            }
          }, "#".concat(elementId));
        });
      };
    },
    actions: {
      error: function error(_error) {
        this.paypalActions.error(_error);
      }
    }
  });

  _exports.default = _default;
});