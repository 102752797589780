define("@dwind/checkout/models/checkout-step-simfork-config", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr"], function (_exports, _fragment, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    availableChoices: (0, _attr.default)('')
  });

  _exports.default = _default;
});