
if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('@dwind/checkout/app')['default'].create({"name":"@dwind/checkout","version":"1.4.15+c0a49b7f"});
  }
}

define('~fastboot/app-factory', ['@dwind/checkout/app', '@dwind/checkout/config/environment'], function(App, config) {
  App = App['default'];
  config = config['default'];

  return {
    'default': function() {
      return App.create(config.APP);
    }
  };
});

