define("@dwind/checkout/mixins/widi-form-component", ["exports", "@dwind/widi-uikit/mixins/widi-form-component"], function (_exports, _widiFormComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _widiFormComponent.default;
    }
  });
});