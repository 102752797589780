define("@dwind/checkout/templates/errors/payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lch0FMIk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"checkout-error error_payment_redirect\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"checkout-error-title layout-row\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"errors.generic.title\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"checkout-error-detail\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"checkout-error-detail-promo-title\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"errors.error_payment_redirect.title\"],[[\"default\",\"htmlSafe\"],[\"errors.generic.detail\",true]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"checkout-error-detail-detail\"],[9],[0,\"\\n\"],[0,\"      \"],[1,[27,\"t\",[\"errors.error_payment_redirect.detail\"],[[\"default\",\"htmlSafe\"],[\"errors.generic.subtitle\",true]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/checkout/templates/errors/payment.hbs"
    }
  });

  _exports.default = _default;
});