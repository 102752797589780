define("@dwind/checkout/models/checkout-step-contract-config", ["exports", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes", "ember-data/attr"], function (_exports, _fragment, _attributes, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    mnpConfig: (0, _attributes.fragment)('mnp-config'),
    tosConfig: (0, _attributes.fragment)('tos-config'),
    contractConditionsLink: (0, _attr.default)('string')
  });

  _exports.default = _default;
});