define("@dwind/checkout/controllers/checkout", ["exports", "@dwind/checkout/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    cart: Ember.computed.alias('model'),
    fastboot: Ember.inject.service(),
    metrics: Ember.inject.service(),
    widiMetrics: Ember.inject.service(),
    steps: Ember.inject.controller('checkout.steps'),
    progress: Ember.computed.alias('steps.step.progress'),
    selectedFlow: Ember.computed.alias('steps.model.flow.meta.selectedFlow'),
    uniqueStreamType: Ember.computed.alias('steps.model.flow.meta.uniqueStreamType'),
    isTied: Ember.computed.alias('steps.model.flow.meta.isTied'),
    isTryAndBuy: Ember.computed.alias('steps.model.flow.meta.isTryAndBuy'),
    isFdo: Ember.computed.alias('steps.model.flow.meta.isFdo'),
    isPPE: Ember.computed.alias('steps.model.flow.meta.isPPE'),
    brand: Ember.computed.alias('steps.model.flow.meta.brand'),
    widiUser: Ember.inject.service(),
    CONSTANTS: _constants.default,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!Ember.get(this, 'fastboot.isFastBoot')) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          window.addEventListener('scroll', function () {
            Ember.set(_this, 'scrolled', window.scrollY > 150);
          });

          if (Ember.get(_this, 'steps.step.flow.meta.flowExpiration.currentDate')) {
            Ember.set(_this, 'currentDate', new Date(_this.steps.step.flow.meta.flowExpiration.currentDate));
          } //  false : true per mettere il carrello aperto al mobile;


          var cartShouldBeOpen = window.innerWidth > 600 ? false : false;
          Ember.set(_this, 'cartOpen', cartShouldBeOpen);
        });
      }
    },
    telcoInCart: Ember.computed('selectedFlow', {
      get: function get() {
        var selectedFlow = Ember.get(this, 'selectedFlow');

        if (selectedFlow == this.CONSTANTS.OPTIONS.DEVICE_ONE_SHOT || selectedFlow == this.CONSTANTS.OPTIONS.BUNDLE_CB) {
          return false;
        }

        return true;
      }
    }).readOnly(),
    timerOn: Ember.computed({
      get: function get() {
        if (!Ember.get(this, 'alreadyFinished') && Ember.get(this, 'steps.step.flow.meta.flowExpiration')) {
          return true;
        }

        return false;
      }
    }).readOnly(),
    pickstoreStreamObserver: Ember.observer('uniqueStreamType', 'pickstoreStreamSelected', function () {
      console;
      Ember.set(this, 'pickstoreStream', this.uniqueStreamType == this.CONSTANTS.OPTIONS.STORE_PICKUP || this.pickstoreStreamSelected);
    }),
    userIsLoggedIn: Ember.computed('widiUser.authorization', {
      get: function get() {
        return !!Ember.get(this, 'widiUser.authorization');
      }
    }).readOnly(),
    totalPrice: Ember.computed('shippingPrice.displayedPrice', 'cart.receipt.totalPrice.{displayedPrice,currency}', {
      get: function get() {
        var shippingPrice = Ember.get(this, 'shippingPrice.price.displayedPrice');
        var displayedPrice = Ember.get(this, 'cart.receipt.totalPrice.displayedPrice');
        var currency = Ember.get(this, 'cart.receipt.totalPrice.currency');

        if (!Ember.isEmpty(shippingPrice)) {
          var total = this._parseAmount(displayedPrice);

          var shipping = this._parseAmount(shippingPrice);

          displayedPrice = "".concat((total + shipping).toFixed(2)).replace(/\./g, ',');
        }

        return {
          displayedPrice: displayedPrice,
          currency: currency
        };
      }
    }).readOnly(),
    _parseAmount: function _parseAmount(amount) {
      return parseFloat(amount.replace('.', '').replace(',', '.'));
    },
    hideCartAndProgress: Ember.computed('steps.step.name', {
      get: function get() {
        var stepName = Ember.get(this, 'steps.step.name');

        if (stepName != undefined) {
          return stepName === 'complete' || stepName === 'customer-identification' || stepName === 'paymentcc';
        }

        return true;
      }
    }),
    showLogin: Ember.computed('steps.step.name', {
      get: function get() {
        var stepName = Ember.get(this, 'steps.step.name');
        return stepName === 'personal';
      }
    }),
    expirationDate: Ember.computed('steps.step.flow.meta.flowExpiration.expirationDate', {
      get: function get() {
        return new Date(this.steps.step.flow.meta.flowExpiration.expirationDate);
      }
    }),
    isFromApp: Ember.computed('steps.step.flow.meta', {
      get: function get() {
        return this.steps.step && Ember.isPresent(this.steps.step.flow.meta.tagInfo.trmInfo);
      }
    }).readOnly(),
    withdrawalLink: Ember.computed('isFromApp', {
      get: function get() {
        if (this.isFromApp) {
          return 'windtre://open-browser?url=' + encodeURIComponent(this.CONSTANTS.LINKS.WIND_DIRITTO_DI_RECESSO);
        } else {
          return this.CONSTANTS.LINKS.WIND_DIRITTO_DI_RECESSO;
        }
      }
    }).readOnly(),
    faqLink: Ember.computed('isFromApp', {
      get: function get() {
        if (this.isFromApp) {
          return 'windtre://open-browser?url=' + encodeURIComponent(this.CONSTANTS.LINKS.WIND_ASSISTENZA);
        } else {
          return this.CONSTANTS.LINKS.WIND_ASSISTENZA;
        }
      }
    }).readOnly(),
    termsLink: Ember.computed('isFromApp', {
      get: function get() {
        if (this.isFromApp) {
          return 'windtre://open-browser?url=' + encodeURIComponent(this.CONSTANTS.LINKS.WIND_CONDIZIONI_ECOMMERCE);
        } else {
          return this.CONSTANTS.LINKS.WIND_CONDIZIONI_ECOMMERCE;
        }
      }
    }).readOnly(),
    contactsLink: Ember.computed('isFromApp', {
      get: function get() {
        if (this.isFromApp) {
          return 'windtre://open-browser?url=' + encodeURIComponent(this.CONSTANTS.LINKS.WIND_CONTATTI);
        } else {
          return this.CONSTANTS.LINKS.WIND_CONTATTI;
        }
      }
    }).readOnly(),
    windStoreLink: Ember.computed('isFromApp', {
      get: function get() {
        if (this.isFromApp) {
          return 'windtre://open-browser?url=' + encodeURIComponent(this.CONSTANTS.LINKS.WIND_STORE);
        } else {
          return this.CONSTANTS.LINKS.WIND_STORE;
        }
      }
    }).readOnly(),
    supportLink: Ember.computed('isFromApp', {
      get: function get() {
        if (this.isFromApp) {
          return 'windtre://open-browser?url=' + encodeURIComponent(this.CONSTANTS.LINKS.WIND_ASSISTENZA);
        } else {
          return this.CONSTANTS.LINKS.WIND_ASSISTENZA;
        }
      }
    }).readOnly(),
    privacyLink: Ember.computed('isFromApp', {
      get: function get() {
        if (this.isFromApp) {
          return 'windtre://open-browser?url=' + encodeURIComponent(this.CONSTANTS.LINKS.WIND_PRIVACY);
        } else {
          return this.CONSTANTS.LINKS.WIND_PRIVACY;
        }
      }
    }).readOnly(),
    generalConditionsLink: Ember.computed('isFromApp', {
      get: function get() {
        if (this.isFromApp) {
          return 'windtre://open-browser?url=' + encodeURIComponent(this.CONSTANTS.LINKS.WIND_CONDIZIONI_GENERALI);
        } else {
          return this.CONSTANTS.LINKS.WIND_CONDIZIONI_GENERALI;
        }
      }
    }).readOnly(),
    currentDateChanged: Ember.observer('steps.step.flow.meta.flowExpiration.currentDate', function () {
      if (Ember.get(this, 'steps.step.flow.meta.flowExpiration.currentDate')) {
        Ember.set(this, 'currentDate', new Date(this.steps.step.flow.meta.flowExpiration.currentDate));
      }
    }),
    flowTimeError: Ember.observer('steps.stepDataError', function () {
      var errors = Ember.getWithDefault(this, 'steps.stepDataError.errors', []);

      if (!Ember.isEmpty(errors)) {
        var errorCode = errors.firstObject.code;
        Ember.set(this, 'errorCode', errorCode);

        switch (errorCode) {
          case 'flow_timeout':
            Ember.set(this, 'alreadyFinished', true);
        }
      }
    }),
    _submitStepAfterLogout: function _submitStepAfterLogout() {
      return this.steps.submitStep(true);
    },
    actions: {
      onCouponAdd: function onCouponAdd(code) {
        Ember.set(this, 'metrics.context.offerCoupon', code);
      },
      showModalLogin: function showModalLogin() {
        /*this.toggleProperty('loginModalOpen');
        const metricsInfo = this.widiMetrics.getBasicMetricsInfo();
        this.metrics.trackPage({
          ...metricsInfo,
          event: 'windCustom',
          eventCategory: 'autenticazione',
          eventAction: 'login ecommerce popup',
          eventLabel: ''
        });*/
      },
      toggleCart: function toggleCart() {
        var isCartOpen = Ember.get(this, 'cartOpen');
        Ember.set(this, 'cartOpen', !isCartOpen);
      },
      logout: function logout() {
        this.widiUser.logout();
        return this.steps.submitStep(true);
      },
      submitStepAfterLogin: function submitStepAfterLogin() {
        this.toggleProperty('loginModalOpen');
        return this.steps.submitStep(true);
      },
      onEnd: function onEnd() {
        var _this2 = this;

        Ember.run.scheduleOnce('afterRender', this, function () {
          if (!Ember.get(_this2, 'alreadyFinished')) {
            Ember.set(_this2, 'alreadyFinished', true);
          }
        });
      },
      updateCurrentDate: function updateCurrentDate(updatedCurrentDate) {
        Ember.set(this, 'currentDate', updatedCurrentDate);
      }
    }
  });

  _exports.default = _default;
});