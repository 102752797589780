define("@dwind/checkout/components/checkout-payment-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    fastboot: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.get(this, 'fastboot.isFastBoot')) {
        Ember.set(this, 'urlBack', window.location.href);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.shouldBeSubmitted) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          document.querySelector('#checkout-post-form').submit();
        });
      }
    }
  });

  _exports.default = _default;
});