define("@dwind/checkout/components/checkout-step-contract", ["exports", "@dwind/widi-flow/mixins/widi-flow-step-component"], function (_exports, _widiFlowStepComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_widiFlowStepComponent.default, {
    stepInternalId: 3,
    store: Ember.inject.service(),
    widiCart: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.stepConfig.mnpConfig) {
        var tariffPlanValues = this.stepConfig.mnpConfig.tariffPlan.list.values;

        if (this.stepData.mnpDetails && tariffPlanValues.length == 1) {
          Ember.set(this, 'stepData.mnpDetails.tariffPlan', tariffPlanValues.firstObject.valueId);
        }

        var operatorList = Ember.get(this, 'stepConfig.mnpConfig.operator.list.values.content');
        var veryMobile = operatorList.findBy('valueId', 'idWINDTRE');

        if (veryMobile) {
          var indexVeryMobile = operatorList.lastIndexOf(veryMobile);
          Ember.set(this, 'stepConfig.mnpConfig.operator.list.values.content.' + indexVeryMobile + '.label', 'Very Mobile');
        }
      }

      var selectedFlow = Ember.get(this, 'selectedFlow');

      if (selectedFlow === 'TELCO') {
        var frontCartItems = this.store.peekRecord('widi-cart', this.widiCart.cartId).frontCartItems;
        var cartOfferObject = frontCartItems.filter(function (item) {
          return item.itemType == 'telco';
        });
        var cartOffer = cartOfferObject ? cartOfferObject.firstObject.productId : null;
        Ember.set(this, 'cartOffer', cartOffer);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.stepConfig.mnpConfig) {
        Ember.set(this, 'portabilityMethods', this.stepConfig.mnpConfig.portabilityMethods);
        Ember.set(this, 'onePortabilityMethod', this.portabilityMethods.length === 1);

        if (this.portabilityMethods.includes('MNP') && !this.stepData.mnpDetails) {
          var mnpDetails = this.store.createFragment('checkout-step-contract-data-mnpdetails');
          Ember.set(this, 'stepData.mnpDetails', mnpDetails);

          if (this.onePortabilityMethod) {
            Ember.set(this, 'mnp', true);
          }
        }
      }

      var mainStatus = Ember.get(this, 'stepData.mainStatus.status');
      Ember.set(this, 'stepConfig.tosConfig.tosMainDetail.status', mainStatus);
      var details = Ember.get(this, 'stepConfig.tosConfig.tosDetails');
      details.forEach(function (detail) {
        var id = Ember.get(detail, 'identifier');
        var tosDetail = Ember.get(_this, 'stepData.tosStatus').findBy('identifier', id);
        Ember.set(detail, 'status', Ember.get(tosDetail, 'status'));
      });
      var tosAccepted = Ember.get(this, 'tosAccepted');
      var anyDetailTrue = details.isAny('status', true);
      Ember.set(this, 'detailsOpen', (tosAccepted || anyDetailTrue) && !mainStatus);
    },
    preContractLink: Ember.computed('isFromApp', {
      get: function get() {
        if (this.isFromApp) {
          var host = window.location.origin;
          var offer = Ember.get(this, 'cartOffer');
          var link = '/precontratto/download?offer=';
          var downloadLink = host + link + offer;
          return 'windtre://open-browser?url=' + encodeURIComponent(downloadLink);
        }
      }
    }).readOnly(),
    actions: {
      clickOnDetail: function clickOnDetail(detail, value) {
        Ember.set(detail, 'status', value);
        var allTrue = !Ember.get(this, 'stepConfig.tosConfig.tosDetails').isAny('status', false);

        if (!value) {
          Ember.set(this, 'stepConfig.tosConfig.tosMainDetail.status', false);
        } else if (allTrue) {
          Ember.set(this, 'stepConfig.tosConfig.tosMainDetail.status', true);
        }
      },
      toggleMain: function toggleMain(status) {
        var details = Ember.get(this, 'stepConfig.tosConfig.tosDetails');
        details.setEach('status', status);

        if (details.isAny('status', false)) {
          Ember.set(this, 'stepData.mainStatus.status', false);
        }

        Ember.set(this, 'stepConfig.tosConfig.tosMainDetail.status', status);
      },
      toggleTooltip: function toggleTooltip(tooltip) {
        this.toggleProperty(tooltip);
        var tooltip_property = Ember.get(this, tooltip);
        Ember.set(this, 'tooltipVisible', tooltip_property);
        Ember.set(this, 'tooltipType', tooltip_property ? tooltip : null);
      },

      /*getConditionsLink() {
        this.stepActions.submitStep(true).then(()=>{
          const contractConditionsLink = get(this, 'stepConfig.contractConditionsLink');
          window.open(contractConditionsLink,'_blank');
        });
      },*/
      sendContractData: function sendContractData() {
        var _this2 = this;

        if (!this.stepData.mnp) {
          Ember.set(this, 'stepData.mnpDetails', null);
        }

        var mainStatus = Ember.get(this, 'stepConfig.tosConfig.tosMainDetail.status');
        Ember.set(this, 'stepData.mainStatus.status', mainStatus);
        var tosStatus = Ember.get(this, 'stepData.tosStatus');
        tosStatus.forEach(function (detail) {
          var id = Ember.get(detail, 'identifier');
          var tosDetail = Ember.get(_this2, 'stepConfig.tosConfig.tosDetails').findBy('identifier', id);

          if (!Ember.isNone(detail)) {
            Ember.set(detail, 'status', Ember.get(tosDetail, 'status'));
          }
        });

        if (tosStatus.isAny('status', false)) {
          Ember.set(this, 'stepData.mainStatus.status', false);
        }

        Ember.set(this, 'stepData.tosAccepted', true); // GTM: Track specific option.

        this.trackOption(this.stepData.mnp ? 'mnp' : 'no mnp');
        this.addMetric('isMnp', this.stepData.mnp);
        this.stepActions.submitStep();
      }
    }
  });

  _exports.default = _default;
});