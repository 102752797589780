define("@dwind/checkout/templates/components/checkout-cart-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vNCJ/sgZ",
    "block": "{\"symbols\":[\"item\",\"@cartLocked\",\"@isTied\",\"@isPPE\",\"@items\"],\"statements\":[[4,\"each\",[[22,5,[]]],null,{\"statements\":[[0,\"  \"],[5,\"checkout-cart-item\",[],[[\"@item\",\"@cartLocked\",\"@isTied\",\"@isPPE\"],[[22,1,[]],[22,2,[]],[22,3,[]],[22,4,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/checkout/templates/components/checkout-cart-items.hbs"
    }
  });

  _exports.default = _default;
});