define("@dwind/checkout/models/widi-flow-step-info", ["exports", "@dwind/widi-flow/models/widi-flow-step-info"], function (_exports, _widiFlowStepInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _widiFlowStepInfo.default;
    }
  });
});