define("@dwind/checkout/serializers/application", ["exports", "@dwind/widi-flow/serializers/widi-serializer"], function (_exports, _widiSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _widiSerializer.default.extend({});

  _exports.default = _default;
});