define("@dwind/checkout/models/storedata", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr"], function (_exports, _fragment, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    dealerId: (0, _attr.default)('string'),
    address: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    province: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    zipCode: (0, _attr.default)('string'),
    dealerType: (0, _attr.default)('string')
  });

  _exports.default = _default;
});