define("@dwind/checkout/components/paper-tab", ["exports", "ember-paper/components/paper-tab"], function (_exports, _paperTab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _paperTab.default;
    }
  });
});