define("@dwind/checkout/models/checkout-step-payment-data", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attr, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    newPaymentMethod: (0, _attr.default)('boolean'),
    selectedPaymentId: (0, _attr.default)('string'),
    userAgent: (0, _attr.default)('string'),
    newPaymentType: (0, _attr.default)('string'),
    paypalToken: (0, _attr.default)('string'),
    earlyTerminationData: (0, _attributes.fragment)('instalments-payment'),
    tiedActivationOneshot: (0, _attr.default)('boolean'),
    tiedActivationInstallmentType: (0, _attr.default)('string')
  });

  _exports.default = _default;
});