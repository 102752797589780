define("@dwind/checkout/adapters/widi-cart-item", ["exports", "@dwind/widi-cart/adapters/widi-cart-item"], function (_exports, _widiCartItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _widiCartItem.default;
    }
  });
});