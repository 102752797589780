define("@dwind/checkout/templates/components/widi-dialog-actions", ["exports", "@dwind/widi-uikit/templates/components/widi-dialog-actions"], function (_exports, _widiDialogActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _widiDialogActions.default;
    }
  });
});