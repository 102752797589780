define("@dwind/checkout/controllers/precontratto/download", ["exports", "@dwind/checkout/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    queryParams: ['offer'],
    fastboot: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        var offer = _this.offer;
        var offerParsed = offer.includes('_') ? offer.replace(/\_/g, '/') : offer;
        var API = _constants.default.PRECONTRACT.API;

        _this.get('ajax').request(API, {
          method: 'GET',
          data: {
            canale: 'ecommerce',
            brand: 'windtre',
            offerta: offerParsed
          }
        }).then(_this._handleResponse.bind(_this)).catch(_this._handleError.bind(_this));
      });
    },
    _handleResponse: function _handleResponse(data) {
      if (data.element.length > 0 && data.element[0].detail.filename) {
        var redirectUrl = 'https://www.windtre.it' + data.element[0].detail.filename;
        window.location.replace(redirectUrl);
      } else {
        throw new Error();
      }
    },
    _handleError: function _handleError() {
      var link = _constants.default.PRECONTRACT.BASE_REDIRECT;
      window.location.replace(link);
    }
  });

  _exports.default = _default;
});