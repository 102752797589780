define("@dwind/checkout/models/checkout-step-personal-config-document", ["exports", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    idCardType: (0, _attributes.fragment)('listinput')
  });

  _exports.default = _default;
});