define("@dwind/checkout/serializers/widi-flow-step", ["exports", "@dwind/widi-flow/serializers/widi-flow-step"], function (_exports, _widiFlowStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _widiFlowStep.default;
    }
  });
});