define("@dwind/checkout/templates/thankyou/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U0nwnITg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"layout-row layout-align-space-around-center checkout-step-complete full-height\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"checkout-step-complete-thankyou\"],[11,\"class\",\"layout-column layout-align-space-around-center tied\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"id\",\"checkout-step-complete-thankyou-title \"],[9],[0,\"\\n      \"],[7,\"h2\"],[9],[0,\"\\n\"],[0,\"        \"],[1,[27,\"t\",[\"steps.complete.thankyou.user.title\"],null],true],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"img\"],[11,\"src\",\"/assets/@dwind/checkout/images/ic_envelope.svg\"],[12,\"alt\",[27,\"t\",[\"steps.complete.thankyou.user.alt\"],null]],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"checkout-step-complete-thankyou-text\"],[9],[0,\"\\n\"],[0,\"      \"],[1,[27,\"t\",[\"steps.complete.thankyou.user.subtitle\"],null],true],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/checkout/templates/thankyou/user.hbs"
    }
  });

  _exports.default = _default;
});