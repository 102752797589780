define("@dwind/checkout/components/checkout-step-fork", ["exports", "@dwind/widi-flow/mixins/widi-flow-step-component"], function (_exports, _widiFlowStepComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_widiFlowStepComponent.default, {
    stepInternalId: 4,
    store: Ember.inject.service(),
    widiList: Ember.inject.service(),
    widiCart: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'simBonusType', this.store.peekRecord('widi-cart', this.widiCart.cartId).simBonusType);

      this._reloadCart();

      this.stepActions.setShippingPrice(null);
      var secondaryActivationPrice = Ember.get(this, 'stepConfig.secondaryActivationPrice');

      if (secondaryActivationPrice) {
        Ember.set(this, 'secondaryActivationPrice', this.formatPrice(secondaryActivationPrice));
        var primaryActivationPrice = Ember.get(this, 'stepConfig.primaryActivationPrice');
        Ember.set(this, 'primaryActivationPrice', this.formatPrice(primaryActivationPrice));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var selectedStream = this.stepData.selectedStream;

      if (selectedStream) {
        Ember.set(this, 'selectedStream', selectedStream);
      }
    },
    actions: {
      onStreamChange: function onStreamChange(value) {
        if (value !== this.CONSTANTS.OPTIONS.STORE_PICKUP) {
          this.stepActions.setStreamPickup(false);
        } else {
          this.stepActions.setStreamPickup(true);

          this._reloadCart();
        }

        Ember.set(this, 'selectedStream', value);
        Ember.set(this, 'stepData.selectedStream', this.selectedStream);

        if (this.simBonusType === 'onlineFree' || this.simBonusType === 'onlineDiscount' || Ember.get(this, 'stepConfig.secondaryActivationPrice')) {
          this.stepActions.submitStep(true).then(this._reloadCart.bind(this));
        }
      },
      sendStream: function sendStream() {
        var selectedStream = Ember.get(this, 'selectedStream');
        Ember.set(this, 'stepData.selectedStream', selectedStream);
        this.addMetric('selectedStream', selectedStream); // GTM: Track specific option.

        this.trackOption((selectedStream || '').replace(/_/gi, ' ').toLowerCase());
        this.stepActions.submitStep();
      }
    },
    formatPrice: function formatPrice(stringPrice) {
      if (stringPrice) {
        stringPrice = stringPrice.replace('.', ',');

        if (stringPrice.indexOf(',') > -1) {
          if (stringPrice.split(',')[1].length < 2) {
            stringPrice = stringPrice.concat('0');
          }
        }

        if (stringPrice === '0,00' || stringPrice === '0') {
          stringPrice = 'gratuita';
        } else {
          stringPrice = stringPrice.concat('€');
        }
      }

      return stringPrice;
    },
    _reloadCart: function _reloadCart() {
      this.widiCart.reload();
    }
  });

  _exports.default = _default;
});