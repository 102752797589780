define("@dwind/checkout/models/checkout-step-payment-config", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attr, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    savedPayments: (0, _attributes.fragmentArray)('paymentmethodinfo'),
    creditCardOwner: (0, _attr.default)('string'),
    totalPrice: (0, _attributes.fragment)('price'),
    availablePaymentTypes: (0, _attr.default)(''),
    paypalToken: (0, _attr.default)('string'),
    tiedActivationPrices: (0, _attributes.fragment)('activationtied'),
    redirectConfig: (0, _attributes.fragment)('paymentredirectconfiguration'),
    cmdRedirect: (0, _attr.default)('boolean')
  });

  _exports.default = _default;
});