define("@dwind/checkout/components/checkout-step-payment", ["exports", "@dwind/widi-flow/mixins/widi-flow-step-component"], function (_exports, _widiFlowStepComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_widiFlowStepComponent.default, {
    stepInternalId: 6,
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    widiCart: Ember.inject.service(),
    validations: Ember.computed({
      get: function get() {
        var _this = this;

        return [{
          message: this.intl.t('errors.field.expiredDate'),
          validate: function validate(inputValue) {
            if (inputValue) {
              var dateInput = new Date(_this.expireYear, _this.expireMonth, 0);
              var maxFuturDate = new Date();
              maxFuturDate.setFullYear(maxFuturDate.getFullYear() + 20);
              return dateInput > new Date() && dateInput < maxFuturDate;
            }
          }
        }];
      }
    }),
    earlyTerminationDataActive: Ember.computed({
      get: function get() {
        return this.selectedFlow == this.CONSTANTS.OPTIONS.BUNDLE_CB || this.selectedFlow == this.CONSTANTS.OPTIONS.BUNDLE_GA;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var redirectConfiguration = Ember.get(this, 'stepConfig.redirectConfig');
      var redirectError = Ember.get(this, 'stepParams.errorCode');

      if (redirectError) {
        Ember.set(this, 'stepData.newPaymentType', null);
      }

      Ember.set(this, 'redirectConfig', redirectConfiguration);
      var savedPayments = Ember.get(this, 'stepConfig.savedPayments');
      Ember.set(this, 'paymentAction', Ember.isEmpty(savedPayments) ? 'startNewPayment' : 'startSavedPayments');
      Ember.set(this, 'newPaymentVisible', Ember.isEmpty(savedPayments));
      var selectedPaymentId = Ember.get(this, 'stepData.selectedPaymentId');
      var selectedPaymentType = savedPayments.findBy('paymentId', selectedPaymentId);

      if (savedPayments && selectedPaymentId && !selectedPaymentType) {
        selectedPaymentType = savedPayments.findBy('invalid', false);
        selectedPaymentId = selectedPaymentType.paymentId;
        Ember.set(this, 'stepData.selectedPaymentId', selectedPaymentId);
      }

      Ember.set(this, 'paymentType', selectedPaymentType ? selectedPaymentType : 'credit_card');

      if (this.earlyTerminationDataActive) {
        Ember.set(this, 'earlyTerminationData', null);
      } else {
        this.setOnEarlyTermination(false);
      }
    },
    _reloadCart: function _reloadCart() {
      this.widiCart.reload();
    },
    setOnEarlyTermination: function setOnEarlyTermination(value) {
      var instalmentsPayment = this.store.createFragment('instalmentsPayment', {
        isInstalmentsPayment: value
      });
      Ember.set(this, 'earlyTerminationData', instalmentsPayment);
    },
    onActivationChange: function onActivationChange(value) {
      Ember.set(this, 'tiedActivationOneshot', value); //console.log(get(this,isInstallment));

      Ember.set(this, 'stepData.tiedActivationOneshot', value);
      this.stepActions.submitStep(true).then(this._reloadCart.bind(this));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var errors = Ember.get(this, 'stepDataError.errors');

      if (!Ember.isEmpty(errors) && errors.code != 'flow_timeout') {
        this.stepActions.stopLoading();
        this.actions.openTimerAlert(false);
      }
    },
    getPaymentLabel: function getPaymentLabel(type) {
      switch (type) {
        case this.CONSTANTS.OPTIONS.NEW_PAYPAL:
        case this.CONSTANTS.OPTIONS.PAYPAL:
          return 'paypal';

        case this.CONSTANTS.OPTIONS.SAVED_CREDIT_CARD:
        case this.CONSTANTS.OPTIONS.NEW_CREDIT_CARD:
          return 'carta di credito';

        default:
          return type;
      }
    },
    actions: {
      openTimerAlert: function openTimerAlert(status) {
        Ember.set(this, 'stopTimer', status);
      },
      startNewPayment: function startNewPayment() {
        var _this2 = this;

        Ember.setProperties(this.stepData, {
          newPaymentType: this.CONSTANTS.OPTIONS.NEW_CREDIT_CARD,
          userAgent: window.WURFL && window.WURFL.form_factor || 'Desktop',
          newPaymentMethod: true
        });
        this.addMetric('paidWith', 'CREDIT_CARD'); // GTM: Track specific option.

        this.trackOption(this.getPaymentLabel(this.CONSTANTS.OPTIONS.SAVED_CREDIT_CARD));
        Ember.set(this, 'stepData.earlyTerminationData', this.earlyTerminationData);
        this.stepActions.submitStep().then(function () {
          _this2.actions.openTimerAlert(true);

          _this2.stepActions.startLoading();
        });
      },
      paypalReset: function paypalReset(error) {
        this.stepActions.restartFlow().then(function () {
          window.location.reload(false);
        });
        this.actions.openTimerAlert(false);
        this.stepActions.showError(error);
      },
      paypalEnd: function paypalEnd(token) {
        var _this3 = this;

        Ember.setProperties(this.stepData, {
          paypalToken: token,
          newPaymentType: this.CONSTANTS.OPTIONS.NEW_PAYPAL,
          paymentMethodData: null,
          userAgent: window.WURFL && window.WURFL.form_factor || 'Desktop',
          newPaymentMethod: true
        });
        this.addMetric('paidWith', this.CONSTANTS.OPTIONS.PAYPAL);
        this.stepActions.stopLoading(); // GTM: Track specific option.

        this.trackOption(this.getPaymentLabel(this.CONSTANTS.OPTIONS.PAYPAL));
        this.stepActions.submitStep().then(function () {
          _this3.actions.openTimerAlert(true);
        });
      },
      paypalCancel: function paypalCancel()
      /* data*/
      {
        this.actions.openTimerAlert(false);
        this.stepActions.stopLoading();
      },
      paypalError: function paypalError(error) {
        this.stepActions.stopLoading();
        this.actions.openTimerAlert(false);
        this.stepActions.showError(error);
      },
      paymentTypeChanged: function paymentTypeChanged(value) {
        var _this4 = this;

        Ember.set(this, 'paymentType', value);

        if (value !== 'credit_card') {
          Ember.set(this, 'shouldBeDisabled', true);
          setTimeout(function () {
            Ember.set(_this4, 'shouldBeDisabled', false);
          }, 3000);
        }

        if (value.paymentId) {
          Ember.set(this, 'paymentAction', 'startSavedPayments');
        } else {
          Ember.set(this, 'paymentAction', 'startNewPayment');
        }
      },
      startSavedPayments: function startSavedPayments() {
        var _this5 = this;

        var selectedPaymentId = Ember.get(this, 'paymentType.paymentId');
        Ember.setProperties(this.stepData, {
          selectedPaymentId: selectedPaymentId,
          userAgent: window.WURFL && window.WURFL.form_factor || 'Desktop',
          newPaymentMethod: false
        });
        var type = this.paymentType.typeaccount; // === 'credit_card' ? this.CONSTANTS.OPTIONS.SAVED_CREDIT_CARD : this.CONSTANTS.OPTIONS.PAYPAL;

        if (type) {
          this.addMetric('paidWith', type);
        } // GTM: Track specific option.


        this.trackOption(this.getPaymentLabel(type));
        this.stepActions.submitStep().then(function () {
          _this5.actions.openTimerAlert(true);
        });
      }
    }
  });

  _exports.default = _default;
});