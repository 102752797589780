define("@dwind/checkout/components/checkout-cart-coupon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    widiCart: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._initCoupon();
    },
    actions: {
      changeCode: function changeCode(value) {
        Ember.set(this, 'errors', null);
        Ember.set(this, 'code', value);
      },
      setCoupon: function setCoupon() {
        Ember.set(this, 'showSpinner', true);
        return this.widiCart.setCoupon(Ember.get(this, 'coupon.labelDetails') ? null : this.code).then(this._handleSuccess.bind(this)).catch(this._handleError.bind(this));
      }
    },
    _initCoupon: function _initCoupon() {
      Ember.set(this, 'code', null);
      var coupon = Ember.get(this, 'widiCart.cart.coupon');
      Ember.set(this, 'coupon', coupon);
      Ember.set(this, 'errors', null);
    },
    _handleSuccess: function _handleSuccess() {
      var _this = this;

      Ember.set(this, 'showSpinner', false);

      if (this.onCouponAdd) {
        this.onCouponAdd(this.code);
      }

      return this.widiCart.reload().then(function () {
        Ember.set(_this, 'errors', null);

        _this._initCoupon();
      });
    },
    _handleError: function _handleError(error) {
      Ember.set(this, 'showSpinner', false);
      Ember.get(error, 'errors').forEach(function (error) {
        Ember.set(error, 'source.pointer', 'coupon.code');
      });
      Ember.set(this, 'errors', error);
    }
  });

  _exports.default = _default;
});