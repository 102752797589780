define("@dwind/checkout/router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    config: Ember.inject.service(),
    location: Ember.computed.alias('config.locationType'),
    rootURL: Ember.computed.alias('config.rootURL')
  });
  Router.map(function () {
    this.route('status', {
      path: '/_status'
    });
    this.route('landing', {
      path: '/landing/:flow_name/:partner'
    });
    this.route('identification', function () {
      this.route('user', {
        path: '/user'
      });
    });
    this.route('checkout', {
      path: '/checkout/:cart_id'
    }, function () {
      this.route('steps', {
        path: '/:flow_name/:flow_id/:step_name/:step_id'
      });
    });
    this.route('errors/payment', {
      path: '/checkout/errors/payment'
    });
    this.route('thankyou', function () {
      this.route('user', {
        path: '/user'
      });
      this.route('dealer', {
        path: '/dealer'
      });
    });
    this.route('precontratto', function () {
      this.route('download', {
        path: '/download'
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});