define("@dwind/checkout/components/checkout-step-customer-identification", ["exports", "@dwind/widi-flow/mixins/widi-flow-step-component"], function (_exports, _widiFlowStepComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_widiFlowStepComponent.default, {
    stepInternalId: 1,
    intl: Ember.inject.service(),
    validations: Ember.computed({
      get: function get() {
        return {
          'stepData.phoneNumber': [{
            message: this.intl.t('errors.field.invalidField'),
            validate: function validate(value) {
              return /^\d+$/.test(value);
            }
          }]
        };
      }
    }),
    actions: {
      checkPhoneNumber: function checkPhoneNumber() {
        Ember.set(this, 'newOtpRequest', false);
        this.stepActions.submitStep(true).then(this._afterPhoneCheck.bind(this));
      },
      onTelChange: function onTelChange(value) {
        Ember.set(this, 'stepData.phoneNumber', value);
        Ember.set(this, 'otpSent', false);
      },
      newOtpRequest: function newOtpRequest() {
        Ember.set(this, 'newOtpRequest', true);
        Ember.set(this, 'otpSent', false);
      }
    },
    _afterPhoneCheck: function _afterPhoneCheck() {
      var _this = this;

      var error = Ember.get(this, 'stepDataError');
      Ember.set(this, 'otpSent', Ember.isEmpty(error));
      Ember.set(this, 'blockOtpRequest', Ember.isEmpty(error));

      if (Ember.isEmpty(error)) {
        var runLater = Ember.run.later(function () {
          Ember.set(_this, 'blockOtpRequest', false);
          Ember.set(_this, 'randomNr', _this.stepActions.generateRandomNumber(1, 100000));
          Ember.set(_this, 'stepDataError', []);
        }, 30000);
        Ember.set(this, 'runLater', runLater);
      }
    }
  });

  _exports.default = _default;
});