define("@dwind/checkout/services/identification", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    config: Ember.inject.service(),
    store: Ember.inject.service(),
    widiFlow: Ember.inject.service(),
    fastboot: Ember.computed({
      get: function get() {
        return Ember.getOwner(this).lookup('service:fastboot') || {};
      }
    }),
    host: Ember.computed.alias('config.apiHostAmbassador'),
    namespace: '/vr/identification',
    contentType: 'application/json; charset=utf-8',
    headers: Ember.computed('fastboot', {
      get: function get() {
        var headers = {};

        if (Ember.get(this, 'fastboot.isFastBoot')) {
          var requestHeaders = Ember.get(this, 'fastboot.request.headers');

          if (requestHeaders.has('x-widi-uuid')) {
            headers['x-widi-uuid'] = requestHeaders.get('x-widi-uuid');
          }
        }

        return headers;
      }
    }),
    validate: function validate(dataReq) {
      var data = {};

      if (dataReq) {
        if (dataReq.token) {
          data.token = dataReq.token;
          return this.post('/user', {
            data: {
              validationType: 'TOKEN',
              data: data
            }
          });
        } else if (dataReq.iccid && (dataReq.orderid || dataReq.orderId)) {
          data.iccid = dataReq.iccid;
          data.orderId = dataReq.orderid || dataReq.orderId;
          return this.post('/user', {
            data: {
              validationType: 'ICCID',
              data: data
            }
          });
        } else if (dataReq.taxcode && dataReq.iccid) {
          data.taxCode = dataReq.taxcode;
          data.iccid = dataReq.iccid;
          return this.post('/user', {
            data: {
              validationType: 'TAXCODE',
              data: data
            }
          });
        }

        console.warn('Empty call to VR user avoided');
        return new Promise(function (res) {
          res({
            link: null,
            iccid: null,
            orderid: null,
            orderId: null,
            taxcode: null
          });
        });
      }

      console.warn('Empty call to VR user avoided pt.2'); // return this.post('/user', { data });
    }
  });

  _exports.default = _default;
});