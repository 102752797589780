define("@dwind/checkout/components/checkout-step-personal", ["exports", "@dwind/widi-flow/mixins/widi-flow-step-component"], function (_exports, _widiFlowStepComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_widiFlowStepComponent.default, {
    stepInternalId: 2,
    widiCart: Ember.inject.service(),
    widiUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    validations: Ember.computed({
      get: function get() {
        var _this = this;

        return {
          'stepData.document.idCardNumber': [{
            message: this.intl.t('errors.field.invalidIdCardNumber'),
            validate: function validate(value) {
              switch (_this.stepData.document.idCardType) {
                case 'idCIIT':
                  return /^([A-Za-z]{2}[0-9]{7})$|^((c|C)[A-Za-z]{1}[0-9]{5}[A-Za-z]{2})$|^([0-9]{7}[A-Za-z]{2})$/.test(value);

                case 'idPPIT':
                  return /^([A-Za-z]{2}[0-9]{7})$/.test(value);

                case 'idPTIT':
                  return /^([A-Za-z]{2}[0-9]{7}[A-Za-z]{1})$|^((U1|u1)[A-Za-z0-9]{7}[A-Za-z]{1})$|^((U1|u1)[B-HJ-NPR-Zb-hj-npr-z]{1}[0-9]{6}[A-Za-z]{1})$/.test(value);

                default:
                  return true;
              }
            }
          }]
        };
      }
    }),
    userLogin: Ember.observer('userHasLoggedIn', function () {
      if (this.userHasLoggedIn) {
        this.stepActions.submitStep(true);
      }
    }),
    userIsLoggedIn: Ember.computed('widiUser.authorization', {
      get: function get() {
        return Ember.isPresent(this.widiUser.authorization);
      }
    }).readOnly(),
    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.get(this, 'step.previous.name') == 'customer-identification') {
        this.widiCart.reload();
      }
    },
    actions: {
      taxcodeOnChange: function taxcodeOnChange(value) {
        Ember.set(this, 'stepData.taxcode', value);

        if (value.length === 16) {
          var document = Ember.get(this, 'stepData.document');

          if (document) {
            Ember.set(document, 'idCardType', null);
          }

          this.stepActions.submitStep(true, false);
        }
      },
      submitPersonalStep: function submitPersonalStep() {
        var selectedStream = Ember.get(this, 'stepConfig.selectedStream');

        if (selectedStream !== null) {
          this.addMetric('selectedStream', selectedStream);
        }

        var email = Ember.get(this, 'stepData.email');
        var pec = Ember.get(this, 'stepData.pec');
        Ember.set(this, 'stepData.confirmEmail', email);
        Ember.set(this, 'stepData.confirmPec', pec);
        if (!Ember.get(this, 'stepConfig.personalDocument')) Ember.set(this, 'stepData.document', null);
        /*if (get(this, 'selectedFlow') == this.CONSTANTS.OPTIONS.DEVICE_ONE_SHOT) {
          set(this,'stepData.document', null);
        }*/

        return this.stepActions.submitStep();
      },
      toggleTooltip: function toggleTooltip(tooltip) {
        this.toggleProperty(tooltip);
        var tooltip_property = Ember.get(this, tooltip);
        Ember.set(this, 'tooltipVisible', tooltip_property);
        Ember.set(this, 'tooltipType', tooltip_property ? tooltip : null);
      }
    }
  });

  _exports.default = _default;
});