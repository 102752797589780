define("@dwind/checkout/components/checkout-cart-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['checkout-cart-item'],
    widiCart: Ember.inject.service(),
    actions: {
      changeCategoryOption: function changeCategoryOption(item, category, value) {
        Ember.set(category, 'selectedOptionId', value);
        var widiCart = this.widiCart;
        return widiCart.updateItem(item).then(this._reloadCart.bind(this));
      }
    },
    _reloadCart: function _reloadCart() {
      var widiCart = this.widiCart;
      return widiCart.reload();
    }
  });

  _exports.default = _default;
});