define("@dwind/checkout/components/checkout-step-complete", ["exports", "@dwind/widi-flow/mixins/widi-flow-step-component"], function (_exports, _widiFlowStepComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_widiFlowStepComponent.default, {
    stepInternalId: 7,
    videoidentification: Ember.computed.alias('stepConfig.videoidentification'),
    improvedNetwork: Ember.computed.alias('stepConfig.improvedNetwork'),
    typeBanner: Ember.computed({
      get: function get() {}
    }).readOnly(),
    stepVr: Ember.computed({
      get: function get() {
        return this.isTied ? 5 : 5;
      }
    }).readOnly(),
    orderCvp: Ember.computed({
      get: function get() {
        return this.selectedFlow == this.CONSTANTS.OPTIONS.BUNDLE_CB || this.selectedFlow == this.CONSTANTS.OPTIONS.BUNDLE_GA;
      }
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);

      this.setBanner();
      this.splitCode();
    },
    setBanner: function setBanner() {
      // ready for optional banner
      // let master = get(this, 'stepData.tagInfo.transactionProducts').findBy('category', 'master');
      // let ropz = get(master, 'sku');
      // if (ropz == 'ROPZ4569' || ropz == 'ROPZ4603') {
      //   set(this, 'bannerDesktop', this.CONSTANTS.ASSETS.BANNER_OPTIONAL_DESKTOP);
      //   set(this, 'bannerMobile', this.CONSTANTS.ASSETS.BANNER_OPTIONAL_MOBILE);
      // }
      if (Ember.get(this, 'stepConfig.videoidentification')) {
        Ember.set(this, 'bannerDesktop', this.CONSTANTS.ASSETS.BANNER_VR_DESKTOP);
        Ember.set(this, 'bannerMobile', this.CONSTANTS.ASSETS.BANNER_VR_MOBILE);
      } else {
        Ember.set(this, 'bannerDesktop', this.CONSTANTS.ASSETS.BANNER_MYWIND_DESKTOP);
        Ember.set(this, 'bannerMobile', this.CONSTANTS.ASSETS.BANNER_MYWIND_MOBILE);
      }
    },
    getDataLayer: function getDataLayer(stepData) {
      var tagInfo = Ember.getWithDefault(stepData, 'tagInfo', {});
      var dataLayer = Ember.assign(tagInfo, this._super(stepData)); // While waiting for cart refactoring, we filter
      // unnecessary products

      dataLayer.transactionProducts = (dataLayer.transactionProducts || []).filter(function (p) {
        return p.category != 'installment';
      }); // In the final step, GTM event should be 'purchase' instead of checkout;

      dataLayer.event = 'purchase';
      return dataLayer;
    },
    // Split the reservation code in single letters
    splitCode: function splitCode() {
      if (Ember.get(this, 'stepConfig.orderNumber')) {
        Ember.set(this, 'splittedCode', this.stepConfig.orderNumber.split(''));
      }
    }
  });

  _exports.default = _default;
});