define("@dwind/checkout/templates/components/checkout-cart-shipping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6s7ZK1IO",
    "block": "{\"symbols\":[\"@shipping\"],\"statements\":[[7,\"div\"],[11,\"id\",\"checkout-cart-shipping-price\"],[11,\"class\",\"layout-row flex layout-align-space-between-center\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"checkout-cart-shipping-price-description\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"cart.shipping\"],null],false],[0,\" \"],[1,[22,1,[\"price\",\"labelName\"]],false],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"checkout-cart-shipping-price-description-data\"],[9],[0,\"\\n\"],[0,\"      \"],[1,[27,\"if\",[[22,1,[\"description\"]],[27,\"concat\",[[22,1,[\"description\"]],\" \",[22,1,[\"formattedDate\"]]],null]],null],true],[0,\"\\n\"],[0,\"      \"],[1,[27,\"if\",[[22,1,[\"timeDescription\"]],[27,\"concat\",[\"<br>\",[22,1,[\"timeDescription\"]]],null]],null],true],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"checkout-cart-shipping-price-amount\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[22,1,[\"price\",\"displayedPrice\"]],\"0,00\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"cart.free_shipping\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"widi-cart-price\",[],[[\"@amount\",\"@currency\"],[[22,1,[\"price\",\"displayedPrice\"]],[22,1,[\"price\",\"currency\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@dwind/checkout/templates/components/checkout-cart-shipping.hbs"
    }
  });

  _exports.default = _default;
});