define("@dwind/checkout/routes/checkout/steps", ["exports", "@dwind/widi-flow/mixins/widi-flow-route"], function (_exports, _widiFlowRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_widiFlowRoute.default, {
    cookies: Ember.inject.service(),
    config: Ember.inject.service(),
    router: Ember.inject.service(),
    widiFlow: Ember.inject.service(),
    widiGtm: Ember.inject.service(),
    widiCart: Ember.inject.service(),
    metrics: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.router.on('routeWillChange', function (_ref) {
        var params = _ref.to.params;
        Ember.set(_this, 'params', params);
      });
    },
    beforeModel: function beforeModel() {
      var flow_id = this.params.flow_id;
      return this.widiFlow.start(flow_id);
    },
    afterModel: function afterModel()
    /*model*/
    {
      var brand = 'windtre';

      try {
        brand = this.widiFlow.flow.toJSON().meta.brand.toLowerCase();
      } catch (ex) {// nothing at the moment.
      }

      var enabled = this.widiGtm.enableGTM(brand);

      if (enabled) {
        Ember.set(this, 'isGTMEnabled', true);
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      if (this.isGTMEnabled) {
        controller.acceptGTM();
      }
    }
  });

  _exports.default = _default;
});