define("@dwind/checkout/components/checkout-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    widiUser: Ember.inject.service(),
    widiMetrics: Ember.inject.service(),
    metrics: Ember.inject.service(),
    username: null,
    password: null,
    actions: {
      login: function login() {
        /*this._startLoading();
        return this.widiUser.login(this.username, this.password, 'wind')
          .then(this._handleLoginSuccess.bind(this))
          .catch(this._handleLoginError.bind(this))
          .finally(this._stopLoading.bind(this));*/
      }
    },
    _handleLoginSuccess: function _handleLoginSuccess() {
      Ember.set(this, 'loginError', false);
      Ember.set(this, 'username', null);
      Ember.set(this, 'password', null);
      var metricsInfo = this.widiMetrics.getBasicMetricsInfo();
      this.metrics.trackPage(_objectSpread({}, metricsInfo, {
        event: 'windCustom',
        eventCategory: 'autenticazione',
        eventAction: 'login ecommerce ok',
        eventLabel: '',
        logged: true
      }));
      this.onSuccess();
    },
    _handleLoginError: function _handleLoginError() {
      Ember.set(this, 'loginError', true);
    },
    _startLoading: function _startLoading() {
      Ember.set(this, 'isLoading', true);
    },
    _stopLoading: function _stopLoading() {
      Ember.set(this, 'isLoading', false);
    }
  });

  _exports.default = _default;
});