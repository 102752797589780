define("@dwind/checkout/utils/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    OPTIONS: {
      VIDEO_IDENTIFICATION: 'VIDEO_IDENTIFICATION',
      MNP: 'MNP',
      SAVED_CREDIT_CARD: 'SAVED_CREDIT_CARD',
      NEW_CREDIT_CARD: 'NEW_CREDIT_CARD',
      CREDIT_CARD: 'CREDIT_CARD',
      PAYPAL: 'PAYPAL',
      NEW_PAYPAL: 'NEW_PAYPAL',
      FREE_COST: '0,00',
      BUNDLE_CB: 'BUNDLE_CB',
      BUNDLE_GA: 'BUNDLE_GA',
      STORE_PICKUP: 'STORE_PICKUP',
      CASH_ON_DELIVERY: 'CASH_ON_DELIVERY',
      DEVICE_ONE_SHOT: 'DEVICE_ONE_SHOT'
    },
    LINKS: {
      WIND_PRIVACY: 'https://www.windtre.it/privacy/',
      WIND_VIDEOIDENTIFICATION: 'https://www.windtre.it/assistenza-servizi/come-funziona-processo-attivazione-online-tramite-video-identificazione/',
      WIND_ECOMMERCE: 'https://windtre.it/windtre-ecommerce',
      WIND_CONDIZIONI_GENERALI: 'https://www.windtre.it/moduli-utili/',
      WIND_PASSA_A_WIND: 'https://www.windtre.it/ecommerce/modulo-passawindtre-pdf',
      WIND_DIRITTO_DI_RECESSO: 'https://wind-images.s3-eu-west-1.amazonaws.com/docs/Diritto_di_Recesso_WINDTRE.pdf',
      WIND_CONDIZIONI_ECOMMERCE: 'https://www.windtre.it/condizioni-ecommerce/',
      WIND_ASSISTENZA: 'https://www.windtre.it/assistenza/',
      WIND_CONTATTI: 'https://www.windtre.it/contattaci',
      WIND_STORE: 'https://www.windtre.it/trova-negozio/'
    },
    ASSETS: {
      BANNER_MYWIND_DESKTOP: 'https://wind-images.s3-eu-west-1.amazonaws.com/banners/banner_checkout_desktop.jpg',
      BANNER_MYWIND_MOBILE: 'https://wind-images.s3-eu-west-1.amazonaws.com/banners/banner_checkout_mobile.jpg',
      BANNER_VR_DESKTOP: 'https://wind-images.s3-eu-west-1.amazonaws.com/banners/banner_videoriconoscimento_desktop.jpg',
      BANNER_VR_MOBILE: 'https://wind-images.s3-eu-west-1.amazonaws.com/banners/banner_videoriconoscimento_mobile.jpg' //BANNER_OPTIONAL_DESKTOP: 'https://wind-images.s3-eu-west-1.amazonaws.com/customernotification/banners/banner_checkout_giga_extra_desktop.jpg',
      //BANNER_OPTIONAL_MOBILE: 'https://wind-images.s3-eu-west-1.amazonaws.com/customernotification/banners/banner_checkout_giga_extra_mobile.jpg'

    },
    PRECONTRACT: {
      API: 'https://www.windtre.it/rest/api/precontratti/',
      BASE_REDIRECT: 'https://www.windtre.it/trasparenza-tariffaria/'
    }
  };
  _exports.default = _default;
});