define('@dwind/checkout/config/environment', [], function() {
  if (typeof FastBoot !== 'undefined') {
return FastBoot.config('@dwind/checkout');
} else {

          var exports = {
            'default': {"modulePrefix":"@dwind/checkout","environment":"development","rootURL":"/","locationType":"auto","EmberENV":{"FEATURES":{},"EXTEND_PROTOTYPES":{"Date":false},"_APPLICATION_TEMPLATE_WRAPPER":false},"APP":{"name":"@dwind/checkout","version":"1.4.15+c0a49b7f"},"ember-metrics":{"includeAdapters":["GoogleTagManager"]},"ember-paper":{"insertFontLinks":false},"paypalEnv":"sandbox","historySupportMiddleware":false,"apiHost":"https://api.ecommerce.int.wind-digital.com","apiHostAmbassador":"https://api.shop.windtre.int.wind-digital.com","newFlowApiHost":"https://api.shop.windtre.int.wind-digital.com/frontend/ob/Developer?targetFE=https://shop.windtre.int.wind-digital.com/init&output=json","fastboot":{"hostWhitelist":["checkout.int.wind-digital.com"]},"sentry":{"dsn":"https://eff21207938c4872a2e21010a47a2287@api.ecommerce.int.wind-digital.com/__sentry/8","whitelistUrls":[{}]},"GTM_CONFIG":{"wind":"GTM-NVDQWTC","dwind":"GTM-NVDQWTC","windtre":"GTM-NVDQWTC"},"exportApplicationGlobal":true}
          };
          Object.defineProperty(exports, '__esModule', {value: true});
          return exports;
        
}
});
